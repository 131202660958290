import axios from "axios";
import { errorHandle } from "@/request/errorHandle";

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { ElLoading, ElMessage } from "element-plus";
import { LoadingInstance } from "element-plus/lib/components/loading/src/loading";

interface RequestInterceptors<T = AxiosResponse> {
  requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig;
  requestInterceptorCatch?: (error: unknown) => unknown;
  responseInterceptor?: (res: T) => T;
  responseInterceptorCatch?: (error: unknown) => unknown;
}
interface RequestConfig<T = AxiosResponse> extends AxiosRequestConfig {
  interceptors?: RequestInterceptors<T>;
  showLoading?: boolean;
}

const DEAFULT_LOADING = false;

class Request {
  instance: AxiosInstance;
  interceptors?: RequestInterceptors;
  showLoading: boolean;
  loading?: LoadingInstance;

  constructor(config: RequestConfig) {
    // 创建axios实例
    this.instance = axios.create(config);

    // 保存基本信息
    this.showLoading = config.showLoading ?? DEAFULT_LOADING;
    this.interceptors = config.interceptors;

    // 使用拦截器
    // 1.从config中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    );
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    );

    // 2.添加所有实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: "正在请求数据",
            background: "rgba(0, 0, 0, 0.5)",
          });
        }

        return config;
      },
      (err) => {
        ElMessage.error(err.data.error.ElMessage);
        return err;
      }
    );
    /**
     * 响应拦截器
     */
    this.instance.interceptors.response.use(
      (res) => {
        // 将loading移除
        setTimeout(() => {
          this.loading?.close();
        }, 3000);
        /**
         * 错误处理  TODO
         */
        const data = res.data;
        return data;
      },
      (err) => {
        // 将loading移除
        this.loading?.close();
        // 例子：判断不同的HttpErrorCode显示不同的错误信息
        if (err.response && err.response.status === 404) {
          console.log("404错误信息~");
        }
        errorHandle(err.status, err.data.ElMessage);
        return err;
      }
    );
  }

  request<T>(config: RequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1.单个请求针对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config);
      }

      // 2.判断是否需要显示loading
      if (config.showLoading == false) {
        this.showLoading = config.showLoading;
      }

      this.instance
        .request<unknown, T>(config)
        .then((res) => {
          // 1.单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res);
          }
          // 2.将showLoading设置为默认值，这样不会影响下一次请求
          this.showLoading = DEAFULT_LOADING;

          // 3.将结果resolve返回出去
          resolve(res);
        })
        .catch((err) => {
          // 将showLoading设置为默认值，这样不会影响下一次请求
          this.showLoading = DEAFULT_LOADING;
          reject(err);
          return err;
        });
    });
  }

  get<T>(config: RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "GET" });
  }

  post<T>(config: RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "POST" });
  }

  delete<T>(config: RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE" });
  }

  patch<T>(config: RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "PATCH" });
  }
}

export default Request;
