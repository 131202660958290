const lang = {
  title: "DEC-互联互通的实时渲染数据引擎",
  header: {
    login: "登录",
    signUp: "注册",
    DECDow: "下载 DEC",
    product: "产品介绍",
    privilege: "特权介绍",
    help: "教程·帮助中心",
    switchLanguage: "切换语言",
    user_tutorial: "用户手册",
    company_address: "北京市朝阳区半截塔路55号 七棵树创意园B2-2 北门",
    copyright: "京ICP备15057682号-1",
    download: "下载",
  },
  login: {
    sign_in: "账号登录",
    email_error_text: "请输入邮箱",
    password_error_text: "请输入密码",
    forgot_password: "忘记密码",
    login_in: "登录",
    terms_of_service: "登录即代表同意【用户隐私协议】和【服务条款】",
    sign_up: "没有账号？去注册？",
    dear_dec_user: "尊敬的nickname，请选择所需购买的套餐",
    sign_with_code: "验证码登录",
    you_agree_to: "登录即代表同意",
    reg_you_agree_to: "注册即既代表同意",
    user_privacy: "【用户隐私协议】",
    and: "和",
    terms_of_service1: "【服务条款】",
    password_change_success: "密码修改完成",
    cheack_email: "您好，邮件已发送至您的邮箱，请前往确认并激活账号",
    reg_success: "注册成功",
    please_enter_email_phone: "账号不能为空",
    email_format_error: "邮箱格式不正确",
    activation_succeeded: "激活成功",
    activation_failed: "激活失败",
    please_enter_the_account: "账号输入有误，请重新输入",
    login_email_iphone: "请输入手机号/邮箱",
    register_channel_code: "请输入邀请码",
    number_code_max6: "输入6位短信验证码",
    switch_account: "切换账号",
  },
  home: {
    core_funs: "核心功能",
    core_funs_contents1_top: "自由配置资产 多人实时协作",
    core_funs_contents1_center:
      "用户可在 DCC 软件和实时渲染引擎两者间自由配置同一套数字资产，例如把动态的资产配置在 DCC 软件端，把静态资产配置在实时渲染引擎端，从而优化和减轻 DCC 软件端对硬件资源的依赖，同时利用实时渲染引擎高效的完成地形编辑系统。",
    core_funs_contents1_bom:
      "DEC 的底层架构可以实现革命性的多人协作的非线性生产流程。",

    core_funs_contents2_top: "DCC 软件端赋予材质 DEC Node 让引擎特效更简单",
    core_funs_contents2_center:
      "DEC 可将实时渲染引擎的材质赋予 DCC 软件中的 Mesh 物体或任意多边面，在 DCC 软件中的 Mesh物体可以和实时渲染引擎中的粒子系统交互碰撞。",
    core_funs_contents2_bom:
      " DEC Node 在 DCC 软件中仅需占用少量资源即可直接调用实时渲染引擎内的模型，可实时获取模型中任意 Transform的位移、旋转、缩放等数值，方便实时渲染引擎特效和 DCC 软件物体互动，无需创建任何蓝图。",
    title1: "互联互通的",
    title2: "实时渲染",
    title3: "数据引擎",
    start_free: "即刻体验",
    document:
      "DATA ENGINE CONNECT（DEC）是智造科技（Virtueal）自主研发的一款3D数据引擎工具，通过打通传统三维软件（DCC软件）和实时渲染引擎之间的数据壁垒，让3D数据实时互联互通，大幅提升使用实时渲染引擎生产3D数字内容的效率和质量。 同时，DEC的底层架构使未来多人协作非线性生产流程成为可能。",
    tap1_button_text1: "资产配置和",
    tap1_button_text2: "非线性生产管线",
    tap1_propertie_effects: "材质特效",
    tap2_button_text1: "视频输出",
    tap2_button_text2: "资产编辑",
    core_funs_contents3_top: "实时浏览渲染效果 独有的 Redraw 功能",
    core_funs_contents3_center:
      "在 DCC 软件的视窗中可以实时看到引擎的渲染效果，利用 Playblast 功能直接输出视频，同时也可以使用 DEC 携带的渲染器渲染出高质量的视频和序列图片。",
    core_funs_contents3_bom:
      "DEC 特有的 Redraw 功能可以完美解决角色高速动作时产生的黑影、模糊等问题。",
    core_funs_contents4_top: "无需导入、导出动画文件（FBX和ABC）即可渲染成片",
    core_funs_contents4_center:
      "DEC 在 DCC 预览窗口中显示实时渲染效果的同时，支持随意编辑模型、制作动画、配置灯光等操作。",
    core_funs_contents4_bom:
      "DEC 支持 DCC（Digital Content Creation）软件中任意类型的变形器和自定义变形器，用户可以设置任意复杂度的绑定，保留了数字内容创作工具在资产制作和动画制作方面的核心优势。无需导入、导出动画文件（FBX和ABC）即可渲染成片。",
    learn_more: "了解DEC更多功能",
    partner: "合作伙伴",
    tap_up: "收起",
    tap_more: "更多详情",
  },
  faq: {
    how_to_use: "如何使用DEC",
    how_to_use_doc:
      "请在官网点击【下载DEC】按钮下载DEC Hub安装程序，下载后完成安装流程。启动DEC Hub后进行账号登录，登录后在【DEC服务】tab下点击【安装DEC】按钮安装DEC，安装成功后点击【启动DEC】按钮即可使用免费版服务。如您已购买专业版服务，需进行激活操作，请参考【如何成为专业版用户】。请在安装前确保本地已安装2016及以上版本的Maya。",
    trird_party: "如何使用第三方登录",
    trird_party_doc:
      "DEC暂时仅支持“手机号”或“邮箱”进行登录或注册，暂不支持第三方登录注册",
    remove_watermark: "如何去水印",
    remove_watermark_doc: "成为【专业版】或【企业版】用户将不会展示水印。",
    pro_user_service: "如何成为专业版用户并激活服务",
    pro_user_service_doc:
      "购买专业版会员服务即可成为专业版用户。购买流程为：官网-特权介绍页-专业版套餐点击【购买服务】按钮，在弹窗内选择合适的套餐和数量，完成支付流程即可。首次购买后，需要在DEC Hub上进行激活服务的操作。激活流程为：点击【激活服务】按钮，选择需要激活的设备数，确认后完成扣除服务月数的操作，即为激活成功。如您对扣费有疑虑，请参考【专业版服务如何扣除服务时间】。",
    company_user: "如何成为企业版用户",
    company_user_doc:
      "企业版是我们提供的定制化服务。如有兴趣可以在官网-特权介绍页-企业版套餐点击【联系我们】按钮，填写相关信息后我们会及时安排相应的工作人员和您联系。也可以在教程·帮助中心页-点击【联系我们】按钮，与我们取得联系。",
    pro_service_time: "专业版服务如何扣除服务时间",
    pro_service_time_doc:
      "专业版服务会按您在【设备管理】中设置的设备使用数量，每个月1日0点进行统一扣费；每次扣除设备数量对应的月数服务；如您购买的服务月数余额不足则会有提示，请您及时续费以免影响您的使用。 新用户首次扣费会在您激活DEC服务的时候计算本月需扣除的月数，不足一月会按小数计算。 注：在每个月最后一天激活服务或者修改设备使用数量后，会扣除下个月一整月的服务，此操作不会在次日0点重复扣费。",
    set_month: "设置本月每日授权设备的作用是什么？",
    set_month_doc:
      "这是服务于局域网团队协作，进行在线设备管理的设置项。 您可以通过编辑此项数值，满足局域网团队在无外网的情况下，也可以获得专业版服务的需求。 “每日授权设备数”指的是当天同时可在线的设备数，如已设置的设备数无法满足团队需要，可修改数值增加设备供团队使用，修改后会按新增设备进行扣费。",
    not_wifi: "无网络/局域网用户如何登录",
    not_wifi_doc:
      "如果您的电脑无法连接外网，无法用账号登录DEC Hub，我们为您提供了局域网登录的服务。 需要至少一台能连接外网的电脑作为管理员账号，登录在【设备管理】tab内可获取到局域网的登录地址，请手动分发给您的团队成员。 局域网用户在登录时选择【局域网登录】，输入地址，即可使用DEC服务。",
    max_number: "局域网登录提示局域网授权设备已达到上限",
    max_number_doc:
      "出现这个提示，是您的团队目前服务同时在线人数已经饱和，请联系您团队的管理员，修改“本月每日授权设备”的数值，增加数值后再次登录即可成功。",
    how_to_invoice: "如何开发票",
    how_to_invoice_doc:
      "线上的功能我们还在研发中，会尽快上线。如您急需开具发票，可在教程·帮助中心-联系我们板块获得我们的联系方式，我们会尽快给您提供相关服务。",
    contact_us: "如何联系我们",
    contact_us_doc:
      "您可以在教程·帮助中心-联系我们或者官网底部信息中获得我们的联系方式。",
    company_details:
      "Virtueal (智造科技)创立于2013年，是中国顶尖的数字内容制作技术解决方案公司。自创立起，就致力于为娱乐内容生产制作提供以技术为驱动的解决方案，包括影视制作技术的研发与升级，实时渲染动画生产流程，影视工业生产中自动化管理系统的建立，虚拟偶像与数字资产等。",
    phone: "电话",
    email: "邮箱",
    address: "地址",
    nickname_place: "请输入要修改的昵称",
  },
  privilege: {
    title: "看见你的想象",
    download_text: "免费下载",
    free_version: "免费版",
    can_enjoy: "您可以享受到的服务",
    real_time_preview: "实时预览",
    maya_open_ue: "Maya端启动UE工程",
    watermark: "带水印",
    without_watermark: "无水印",
    maya_limited: "MAYA端限制10万三角面",
    maya_not_limited: "MAYA端无面数限制",
    not_use: "不可渲染",
    max_size: "最大可渲染尺寸：2k",
    max_size1: "最大可渲染尺寸：尺寸无上限",
    maya_objects_ue: "Maya端引用UE端物体实例",
    contact_us_message: "联系我们的团队以了解更多信息",
    free_trial: "免费体验",
    purchase_service: "购买服务",
    contact_us: "联系我们",
    min_number: "低至￥",
    yuan_day: "元 / 天",
    maya_output: "支持Maya端超采样率输出",
    feature_comparision: "版本异同对比",
    classify: "分类",
    authorized: "授权",
    maximum_range: "尺寸上限2k",
    not_range: "尺寸无上限",
    render: "渲染",
    render_options: "Movie Render Queue",
    render_options_config: "可存渲染设置",
    none: "无",
    controls_blue_ui: "控件、蓝图、技术、UI",
    maya_scene_file: "Maya文件对应UE levelmap",
    maya_edit_mo: "Maya端可编辑模型",
    maya_import_ue: "Maya端当前场景导入UE",
    maya_cache: "支持Maya端任意模型缓存",
    maya_display_only: "Maya端物体只显示线框（开关），并且可以选择",
    maya_modifier: "支持Maya端任意变形器",
    maya_controller: "支持MAYA端任意控制器",
    all_ue_materials: "支持UE端所有材质",
    maya_preview_ue_materials: "Maya端浏览UE工程内的材质",
    maya_meshes_ue: "Maya端给模型赋予UE材质",
    maya_controllables: "Maya端可控制（链接、K动画、赋值）UE端材质属性",
    maya_replace_pic: "可在Maya端替换UE端贴图",
    maya_udim_pic: "支持Maya端UDIM贴图（UE4.23支持 UDIM）",
    ue_replace_maya: "UE端修改材质可以覆盖MAYA端属性",
    maya_cpu: "支持Maya物体对UE GPU粒子碰撞",
    maya_ue_pz: "支持Maya端与UE端任意物理碰撞",
    mayas_default_point: "支持Maya默认的环境光源，方向光源，点光源，聚光源",
    maya_light: "支持Maya面光源",
    maya_led_params: "Maya灯光显示UE的灯光参数（颜色，强度，灯光组）",
    maya_led_display_params: "Maya灯光显示UE的灯光参数（其它）（附带文档）",
    maya_camera: "支持Maya端摄像机显示UE端摄像机的属性（景深）",
    maya_camera_other: "支持Maya端摄像机显示UE端摄像机的属性（其它）",
    maya_ue_position: "将Maya相机移动到UE相机位置",
    maya_animation: "支持Maya端做任何形式的动画",
    maya_ue_with: "Maya端时间与UE端Sequence时间同步",
    maya_side: "MAYA端启动UE工程",
    model: "模型",
    maya_ys: "支持MAYA端任意布料解算",
    ue_426: "UE 4.26",
    ue_427: "UE 4.27",
    ue_50: "UE 5.0",
    maya_versions: "Maya版本支持",
    ue_versions: "UE版本支持",
    side: "窗口",
    animation: "动画",
    camera: "摄像机",
    led: "灯光",
    cpu: "物理",
    materials: "材质",
    modifier: "绑定",
    add_engine: "AddEngine Transform Delegate",
    maya_2017: "Maya2017",
    maya_2018: "Maya2018",
    maya_2019: "Maya2019",
    maya_2020: "Maya2020",
    maya_2022: "Maya2022",
    maya_2023: "Maya2023",
    movie_render: "Movie RenderQueue渲染（MIRROR）",
    free_plate_form_use: "仅支持本设备使用",
    pro_plate_form_use: "可授权同局域网设备",
    enterprise_plate_form_use: "无上限授权同服务器设备",
    detail: "了解详情",
    pay_error: "支付异常，请检查您是否支付成功",
  },
  register: {
    email_register: "邮箱注册",
    register: "注册",
    terms_of_service: "注册即代表同意【用户隐私协议】和服务条款",
    login_to: "有账号？去",
    set_register_password: "请设置登录密码",
  },
  forgot_password: {
    change_password: "修改密码",
    enter_email: "输入邮箱",
    enter_password: "请输入新密码",
    again_enter_password: "请再次输入新密码",
    next_step: "下一步",
    back_to_login: "返回登录",
    sent_successfully: " 发送成功",
    has_password_url:
      "已将修改密码链接发送至您{email}邮箱<br />你可通过邮件内内容确定修改",
    view_now: "立即查看",
    re_enter_email: "重新输入邮箱",
    login_in_now: "立即登录",
    reset_password: "重置密码",
    reset_password_massage:
      "我们收到了你重置密码的申请，请点击下方按钮进行重置。",
    confirm: "确定",
    url_invalid_text: "该修改密码链接已失效<br />请重新获取修改密码链接。",
    emails_change: "使用邮箱修改密码",
    use_iphone_change_password: "使用手机号修改密码",
  },
  user_center: {
    major_version: "专业版",
    company_version: "企业版",
    trial_version: "体验版",
    account_info: "账号信息",
    order_info: "账户管理",
    change_password: "修改密码",
    set_password: "设置密码",
    quit: "退 出",
    personal_info: "个人信息",
    nick_name: "昵称：",
    email: "邮箱：",
    iphone: "手机：",
    major_version_arrears: "专业版：已欠费n个月",
    major_version_service: "专业版：服务有效期剩余*个月",
    company_version_service: "服务有效期至",
    top_up: "充值",
    renewal: "去续费",
    service_months: "服务有效期剩余",
    integral: "积分",
    Exchange: "兑换商品",
    integral_rule:
      "您可通过邀请好友/购买服务等操作来获取积分，具体获取渠道您可在积分商城右上角查看。积分可在积分商城内兑换体验时长/台月~",
    invitation_code: "邀请码",
    invitation_url: "邀请链接",
    order_number: "订单号",
    transaction_order_number: "交易单号",
    payment_time: "支付时间",
    service_name: "服务名称",
    amount: "金额",
    prev_page: "上一页",
    next_page: "下一页",
    submit: "提交",
    submit_success: "提交成功",
    change_password_url_text:
      "已将修改密码链接确认发送至您的XXX邮箱，你可通过邮件内内容确定修改",
    view_now: "立即查看",
    password_complate: "修改完成",
    confirm: "确定",
    please_select_package: "尊敬的138****1234用户，请选择所需购买的套餐",
    choose_package: "选择套餐：",
    purchase_month_1: "购买1个月",
    purchase_month_6: "购买6个月",
    purchase_month_12: "购买12个月",
    save_195: "节省195元",
    save_533: "节省533元",
    payment_method: "支付方式",
    wechat: "使用微信支付",
    api_pay: "使用支付支付",
    paypal: "PayPal",
    purchase_agreement0: "购买即视为您同意",
    purchase_agreement1: "《购买服务协议》",
    purchase_agreement2: "并同意按约开通专业版会员服务",
    money: "￥",
    wechat_code_payment: "微信扫码支付",
    payment_abnormal: "支付异常，请检查您是否支付成功",
    use_other_payment: "使用其他付款渠道",
    payment_number_text: "调整购买数量",
    payment_success: "支付成功",
    dec_success_payment:
      "感谢成功购买DEC专业版服务，有效期n月，起效时间从登录并激活MAYA插件起，按自然天开始计算。",
    contact_us: "联系我们",
    video: "视频教程",
    base_info: "为了能更专业的提供企业服务，请您花几分钟时间填写基本信息",
    company_name: "公司名称：",
    please_enter_company_name: "请输入您公司的名称",
    website_address: "官网地址：",
    please_enter_address_url: "请输入您公司官网地址",
    headquarters_location: "总部驻地：",
    please_enter_company_headquarters: "请输入您公司总部驻地",
    team_software: "使用软件的团队规模：",
    please_team_software: "请选择的团队规模",
    team_people: "1~20人",
    main_bus_multiple: "主营业务（可多选）：",
    animated_film: "动画电影",
    animated_drama: "动画番剧",
    vfx: "VFX",
    other: "其他",
    maya_vresion: "MAYA版本（可多选）：",
    not_use_maya: "未使用MAYA",
    ue_version: "UE版本（可多选）：",
    version_before: "{version}以下",
    not_use_ue: "未使用UE",
    internally_version: "内部编译版本",
    renderer_used: "使用的渲染器（可多选）：",
    arnold: "Arnold",
    red_shift: "Red Shift",
    vray: "Vray",
    renderman: "Renderman",
    real_time_rendering: "使用的实时渲染引擎（可多选）：",
    none: "无",
    did_you_use_dec: "您是否使用过DEC/MIRROR（单选）:",
    used: "使用过",
    version: "版本号",
    no_used: "未使用过",
    how_know: "您如何知道DEC/MIRROR(可多选）：",
    friend_recommend: "朋友推荐",
    chinese_video: "国内视频网站（腾讯视频，B站等）",
    other_video: "国外视频网站（Youtube，Vimeo等）",
    public_account: "智造科技官方微信公众号",
    official_website: "智造科技/DEC官网",
    most_interested: "您对DEC/mirror最感兴趣的功能(可多选）：",
    viewport_2: "Viewport 2.0实时预览渲染效果",
    complex_animation: "实现复杂动画实时渲染",
    click_to_push: "一键推送MAYA资产进引擎",
    playblast_output: "Playblast实时输出",
    free_allocation: "MAYA和UE4资产自由分配",
    ue_large_scenes: "UE大场景高效制作",
    prefered_title: "您如何称呼：",
    please_enter_name: "请输入您的姓名",
    your_phone_and_mobile: "您的电话/手机号：",
    please_your_phone_and_mobile: "请输入您的电话/手机号",
    your_job_title: "您的职位：",
    your_email: "您的邮箱：",
    please_enter_job: "请输入您的职位",
    please_enter_email: "请输入您的邮箱",
    special_info_remark: "特殊信息备注：",
    leave_your_remark: "请输入备注信息",
    contact_you: "我们将会尽快答复并安排相应人员和您联系",
    cancel: "取 消",
    faq: "常见问题",
    video_tutorial: "视频教程",
    phone: "电话",
    not_order_info: "暂无订单信息...",
    renew: "购买",
    service_valid: "服务有效剩余",
    months: "月",
    price: "单价",
    remark: "备注",
    please_nickname: "请输入名称",
    edit_success: "修改成功",
    please_code: "请输入验证码",
    complete: "完成",
    phone_change_password: "使用手机修改密码",
    send_code: "发送验证码",
    password_none: "请输入密码",
    code_none: "验证码不能为空",
    confirm_none: "输入密码",
    confirm2_none: "请再次输入新密码",
    use_r_code: "使用微信扫一扫 扫描二维码支付",
    order_ing: "订单处理中",
    order_error: "订单失败",
    order_overtime: "订单超时关单",
    device_months: "每台/月",
    device_month: "1-9台/月",
    device_month_10_49: "10-49台/月",
    device_month_50: "50+台/月",
    current_price: "当前单价",
    v_code_velid: "V码验证",
    please_v_code: "请输入V码并使用",
    please_v_code1: "请输入V码",
    use_v_code: "使用V码",
    canel: "取消",
    quantity: "购买数量",
    device_month1: "台/月",
    actual_amount: "实付金额",
    please_iphone: "请输入手机号",
    device_month_membership: "台/月会员服务",
    quit_login: "确定退出登录？",
    exit_success: "退出成功！",
    change_email: "修改绑定邮箱",
    discount: "折优惠",
    limit_number: "限购数量",
    verification_succeeded: "验证成功！",
    payment_succeeeded:
      "您已成功购买DEC专业版XXX月服务，请先下载并安装DECHub，登录后按提示激活即可享受会员服务。",
    view_the_order: "查看订单",
    help1: "如有任何疑问，您可前往",
    help2: "帮助中心",
    help3: "查阅信息或联系我们获得服务支持。",
    people: "人",
    rewards_service_name: "服务",
    rewards_service_time: "服务时间",
    rewards_service_amount: "积分", //变动
    rewards_give_ty: "赠送{supplyInfo}台月服务",
    rewards_add_pc: "增加{supplyInfo}台授权设备",
    rewards_receive_ty: "受赠{supplyInfo}台月服务",
    rewards_recharge_ty: "购买{supplyInfo}台月服务",
    rewards_auto_deduct_pc: "续费当月{supplyInfo}台设备",
    rewards_active_pc: "激活{supplyInfo}台授权设备",
    rewards_open_company_day: "开通{supplyInfo}天体验版",

    rewards_change_company_day: "兑换{supplyInfo}天体验版积分奖励",
    rewards_change_ty: "兑换{supplyInfo}台月积分奖励",

    rewards_invite_register_user: "邀请{content}注册用户",
    rewards_invited_register_user: "受{content}邀请注册账户",
    rewards_invited_first_order: "邀请新用户{content}付费",
    rewards_system_invited_register_user: "{content}渠道注册",

    rewards_change_tm_consume: "兑换{supplyInfo}天体验版",
    rewards_change_ty_consume: "兑换{supplyInfo}台月",
    rewards_register_user: "{content}注册账户",
    not_rewards_info: "暂无积分记录信息",
    copy_success: "复制成功",
    copy_fail: "复制失败",
    per_center: "个人中心",
    order_list: "订单列表",
    account_list: "账单列表",
  },

  not_language: {
    ipone_not_none: "手机号不能为空",
  },
  user_tut: {
    web_use: "网站功能介绍",
    dec_install: "DEC Hub安装及使用",
    dec_functions: "DEC 功能介绍",
  },
  v_code: {
    normal_price: "未使用V码",
    discount: "优惠{number}折服务",
    per_device: "优惠{number}每台/月",
    discount_button_text: "使用V码优惠",
    buy_limit: "该V购买最多可购买X台月服务",
  },
  us: {
    base_info: "为了能更专业的提供企业服务，请您花几分钟时间填写基本信息",
    company_name: "请输入您公司名称~",
    company_url: "请输入公司官网地址~",
    company_address: "请输入您公司总部驻地~",
    company_teams: "请输入团队规模~",
    main_business: "请输入主营业务~",
    maya_version: "请选择Maya版本~",
    ue_version: "请选择UE版本~",
    select_use: "请选择渲染器~",
    check_use: "请选择实时渲染引擎~",
    nown_dec_mirror: "请填写您如何知道DEC/MIRROR~",
    dec_mirror: "请填写您对DEC/MIRROR最感兴趣的功能",
    what: "请输入您如何称呼~",
  },
  gift: {
    gift_service: "赠送服务",
    please_input_gift_account: "请输入赠送服务的账号",
    vlid_user: "请输入您的验证码用于验证您的身份",
    gift_account: "赠送账号：",
    please_input_gift_number: "请输入赠送台月数",
    gift_success: "赠送成功",
    authentication: "验证身份",
    tips: "验证码将发送至赠送账号绑定的手机号/邮箱内，请联系对方查看验证码",
    balance: "余额剩余N台月，不足以支持您本次增送X台月",
    balance_title: "余额不足",
    back: "返回",
    limit: "每月最后一日23:00~次日02:00为系统维护时间，不可新增设备数/赠送服务",
    limit_title: "账户锁定中",
  },
  information: {
    order: "订单",
    account: "账单",
    integral: "积分",
    balance: "账户余额",
    termOfValidity: "有效期至",
    device_months: "台月",
    topUp: "充值",
    gift: "赠送",
    not_bill_info: "暂无符合筛选条件的账单信息…",
    bill: {
      subHistorySerialNo: "流水号",
      createTime: "服务时间",
      tradeType: "服务类型",
      content: "服务内容",
      change: "余额变动",
      afterSubBalance: "账户余额",
      keyword: "赠送人/受赠人",
    },
    searchTime: {
      startTime: "开始时间",
      endTime: "结束时间",
    },
    searchType: {
      consume: "增加设备",
      recharge: "购买服务",
      active: "激活服务",
      give: "赠予服务",
      receive: "受赠服务",
      auto_deduct: "月初自动扣费",
      all: "全部类型",
      increase: "余额增加",
      diminished: "余额减少",
      change: "兑换商品",
    },

    keyword: "搜索",
    serviceContent: {
      consume: {
        before: "增加",
        after: "台授权设备",
      },
      recharge: {
        before: "购买",
        after: "台/月服务",
      },
      active: {
        before: "激活",
        after: "台授权设备",
      },
      give: {
        before: "赠送",
        after: "台/月",
      },
      receive: {
        before: "赠送您",
        after: "台/月服务",
      },
      auto_deduct: {
        before: "续费当月",
        after: "台设备",
      },
      change: {
        before: "兑换",
        after: "台/月",
      },
    },
  },
  novice_guidance: {
    novice_guidance_home: {
      title: "您好!",
      before_name: "尊敬的",
      after_name:
        "欢迎来到DEC官网，是否需要我来帮您简单介绍下我们官网各个板块的功能~",
      cancel_btn: "取消",
      confirm_btn: "确定",
    },
    step_boxOne: {
      before_step_name: "在",
      step_name: "产品介绍",
      after_step_name: "下，您可以了解DEC的核心优势和应用场景",
    },
    step_boxTwo: {
      before_step_name: "在",
      step_name: "特权介绍",
      after_step_name: "下，您可了解我们不同版本服务的差异性以及购买相应的服务",
    },
    step_boxThree: {
      before_step_name: "若碰到不懂或者疑惑的地方您可在",
      step_name: "教程·帮助中心",
      after_step_name: "下，查看“常见问题”",
    },
    step_boxFour: {
      before_step_name: "也可以查看我们的“",
      step_name: "用户手册",
      after_step_name: " ”",
    },
    step_boxFive: {
      before_step_name: "还可以直接在我们官网右下角“",
      step_name: "联系我们",
      after_step_name: "”，与我们客服人员直接沟通",
    },
    step_btn_next: "下一步",
    step_btn_over: "完成",
    step_btn_skip: "跳过",
  },
  mobile: {
    download_to_pc: "请到PC端下载DEC",
    donwload_link: "下载链接",
    copy_link: "复制链接",
    close: "关闭",
  },
  topic: {
    your_friend: "您的朋友{userName}邀请您体验DEC",
    reg_to_points: "注册即可获得积分，可兑换",
    free_7day: "免费体验7天",
    content1: "自由配置资产 多人实时协作",
    content2: "实时浏览渲染效果 独有的 Redraw 功能",
    content3: "DCC 软件端赋予材质 DEC Node 让引擎特效更简单",
    content4: "无需导入、导出动画文件（FBX和ABC）即可渲染成片",
    sign_up: "立即注册",
    features: "DEC产品特点",
  },
  pointsMall: {
    titleName: "积分商城",
    score: "积分",
    Exchange: "兑换",
    successfully: "兑换成功",
    trialVersion: "体验版",
    day: "天",
    deviceMonths: "台月",
    how: "如何获取积分",
    time: "{offTime}后下架",
    btn: "确定",
    dialogText: "兑换{goodname}商品需要{goodprice}积分，所剩积分不足以兑换。",
    fast: "快速获取积分",
    register: "注册即可获得积分",
    text: "您可在个人中心查看您的邀请码或生成您的邀请链接发送于朋友，朋友通过您的邀请链接注册DEC账号后，您和您的朋友都可已获得积分。也可以通过购买DEC专业版服务或增加可授权设备数来获取积分。",

    tableTitle1: "快速获取积分",
    tableTitle2: "注册即可获得积分",
    fastName: ["邀请好友", "购买服务", "增加授权设备数", "受邀好友购买服务"],
    fastSocre: [
      "积分(每个好友)",
      "积分(每台月)",
      "积分(每设备)",
      "积分(好友首单)",
    ],
    fastNum: ["无上限", "仅限一次"],
    registertName: ["受邀注册", "普通注册"],
    registerSocre: "积分",
  },
  active_page: {
    title_left: "初客见面礼",
    title_right: "邀请赢积分",
    button: "立即行动",
    redeem: "积分兑换",
    active_type: {
      register_title: "注册",
      register_centent: "免费体验7天",
      invitation_title: "邀请好友",
      invitation_centent: "兑换体验时长",
      purchase_title: "好友首单购买",
      purchase_centent: "邀请人获得一台月奖励",
    },
    how_get: {
      before: "您可在",
      name: "个人中心",
      after:
        "查看您的邀请码或生成您的邀请链接发送于朋友，朋友通过您的邀请链接注册DEC账号后，您和您的朋友都可已获得积分。",
    },
    where_get: {
      title: "在哪里兑换？",
      before: "在",
      name: "个人信息/账户管理",
      after:
        "点击“兑换商品”可直接进入积分商城,也可以直接在官网首页，点击“积分兑换”进入积分商城。",
    },
    how_free: "如何免费体验DEC全部功能？",
    quick_view: "快速查看",
    free: "限免",
  },
  videoInfo: {
    backToList: "返回视频列表",
    moreVideo: "更多视频",
  },
};

export default {
  lang,
};
