import { createStore } from "vuex";

import createPersistedstate from "vuex-persistedstate";
import {
  getCookie,
  setCookie,
  getLocalStorage,
  setLocalStorage,
} from "@/utils/cookies";
import {
  UserInfoType,
  ServiceType,
  LoginServiceType,
  LoginResult,
} from "@/types/login";
import { getUserInfo, getFoundUserService } from "@/api/login";
import i18n from "@/lang";
import modules from "./modules/index";
import isMobile from "ismobilejs";

type State = {
  userInfo: UserInfoType;
  isLogin: string;
  isLoginDialog: boolean;
  registerDialog: boolean;
  appLanguage: string;
  driverState: boolean;
  driverExt: unknown;
  guidanceData: string;
  isMobile: boolean;
  showMobileDownloadTips: boolean;
  howToGetScoreState: boolean;
  isShowActivePage: boolean;
  activePageState: boolean;
};
export default createStore({
  state: {
    isLogin: getCookie("token"),
    // 个人信息
    userInfo: getLocalStorage("userInfo") || {},
    // 登录弹框
    isLoginDialog: false,
    // 注册弹框
    registerDialog: false,
    // 默认语言包
    appLanguage: "zh",
    // 是否已经启动新手引导
    driverState: false,
    driverExt: null,
    // 新手引导数据
    guidanceData: "",
    isMobile: isMobile().any,
    showMobileDownloadTips: false,
    // 如何获取积分弹窗
    howToGetScoreState: false,
    // 是否显示曝光页
    isShowActivePage: getLocalStorage("showActivePage")
      ? getLocalStorage("showActivePage")
      : false,
    // 曝光页显示隐藏的状态
    activePageState: getLocalStorage("activePageState")
      ? getLocalStorage("activePageState")
      : false,
  },
  mutations: {
    setIsToken(state: any, active: boolean) {
      state.isLogin = active;
    },
    // 显示登录
    showLogin(state: any) {
      state.isLoginDialog = true;
      state.registerDialog = false;
    },
    // 隐藏登录
    hideLogin(state: any) {
      state.isLoginDialog = false;
    },
    // 显示注册
    showRegister(state: any) {
      if (!state.isLogin) {
        state.registerDialog = true;
        state.isLoginDialog = false;
      }
    },
    // 隐藏注册
    hideRegister(state: any) {
      state.registerDialog = false;
    },
    // 切换语言状态
    setAppLanguage(state: any, data: string) {
      state.appLanguage = data;
    },
    // 新手引导状态
    setDriverState(state: any, data: string) {
      state.driverState = data;
    },
    setDriverExt(state: any, data: any) {
      state.driverExt = data;
    },
    guidanceData(state: any, obj: any) {
      state.guidanceData = obj;
    },
    setUserInfo(state: State, userInfo: UserInfoType) {
      state.userInfo = userInfo;
    },
    changeMobileDownloadTips(state: State, val) {
      state.showMobileDownloadTips = val;
    },
    howToGetScoreState(state: State, val) {
      state.howToGetScoreState = val;
    },
    isShowActivePage(state: any, active: boolean) {
      state.isShowActivePage = active;
    },
    activePageState(state: any, active: boolean) {
      state.activePageState = active;
    },
  },
  actions: {
    setDriverExt(context: any, data: any) {
      context.commit("setDriverExt", data);
    },
    setDriverState(context: any, data: any) {
      context.commit("setDriverState", data);
    },
    setAppLanguage(context: any, data: string) {
      context.commit("setAppLanguage", data);
    },
    changeMobileDownloadTips(context: any, data: boolean) {
      context.commit("changeMobileDownloadTips", data);
    },
    getUserInfo(context: { commit: (arg0: string, arg1?: unknown) => void }) {
      let userService: ServiceType = {
        serviceType: 4,
        balance: "",
        title: "",
        text: "",
        time: "",
        className: "auth-free-class",
      };
      getUserInfo().then((res: LoginResult) => {
        if (res.code === 0) {
          let user = res.data;
          user.image =
            "http://m.qpic.cn/psc?/V52ZlP0n3C30mm13GF2D1Hidzk46TuRK/TmEUgtj9EK6.7V8ajmQrEAgSHDFAV7vPKnYpV7BPA4Ild3VTs.XDlmZWQWBDZtuOuCnlkUGcH8ixUbBqCmUTPIDsm8CSJmEBSIWkKEDDRCM!/b&bo=KAAoAAAAAAADFzI!&rf=viewer_4";
          // 获取服务
          getFoundUserService().then((res: LoginServiceType) => {
            if (res.code == 0) {
              const userType = res.data.serviceType;
              if (userType == 3) {
                userService.title = "企业版";
                userService.balance = res.data.companyServiceExpirationDate;
                userService.text = "服务有效期至";
                userService.time = "";
                userService.serviceType = userType;
                userService.className = "company-service-class";
              } else if (userType != -1) {
                userService.title = "专业版";
                userService.balance = res.data.balance;
                userService.text = "服务有效期剩余";
                userService.time = "月";
                userService.serviceType = userType;
                userService.className = "pro-service-class";
              }
              let userInfo = Object.assign(user, userService);
              setCookie("uid", userInfo.urId || "");
              context.commit("setUserInfo", userInfo);
              setLocalStorage("userInfo", userInfo);
              context.commit("setIsToken", true);
              context.commit("hideLogin");
            }
          });
        }
      });
    },
  },
  modules,
  getters: {
    getDriverState(state: any) {
      return state.driverState;
    },
    getDriverExp(state: any) {
      return state.driverExt;
    },
    getAppLanguage(state: any) {
      return state.appLanguage;
    },
  },
  plugins: [
    createPersistedstate({
      reducer(val: any) {
        return {
          appLanguage: val.appLanguage,
          isShowActivePage: val.isShowActivePage,
          activePageState: val.activePageState,
        };
      },
    }),
  ],
});
