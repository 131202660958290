import http from "./request";
import { BASE_URL, TIME_OUT } from "./config";
import { getCookie } from "@/utils/cookies";
import CryptoJS from "crypto-js";
import { ElMessage } from "element-plus";
import store from "@/store";
import { logout } from "@/utils";
import { AxiosError } from "axios";
const request = new http({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  withCredentials: false,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = getCookie("token");
      if (config.url?.indexOf("v-oauth-server/oauth/token") != -1) {
        const requestTypeText = store.state.isMobile
          ? "h5:h5"
          : "webApp:webApp";
        let words = CryptoJS.enc.Utf8.parse(requestTypeText as any);
        let auth = CryptoJS.enc.Base64.stringify(words);
        config.headers!.Authorization = `Basic ${auth}`;
      } else {
        if (token) {
          config.headers!.Authorization = `Bearer ${token}`;
        }
      }
      config.headers!.decLanguage = store.getters.getAppLanguage;
      //请求成功的拦截
      return config;
    },
    requestInterceptorCatch: (err) => {
      //请求失败的拦截
      return err;
    },
    responseInterceptor: (res: any) => {
      if (res.config.headers.statistical) {
        return res;
      }
      const result = res.data;
      // const errorCodes = [
      //   "VEAN012",
      //   "VEPD020",
      //   "VEPD021",
      //   "VEPD022",
      //   "VEPD026",
      //   "VEUR025",
      // ];
      if (result.code === 0) {
        return res;
      } else if (typeof result !== "undefined") {
        // config.headers.unErrorMsg 不展示错误提示
        if (Number(result.code) === 1 && !res.config.headers.unErrorMsg) {
          ElMessage.error(result.msg);
        }
        return res;
      }
      //响应成功的拦截
    },
    responseInterceptorCatch: (err: AxiosError) => {
      //响应失败的拦截
      if (401 === err.response.status) {
        logout();
        ElMessage.closeAll();
        ElMessage.error("登录过期请重新登录");
      }
      return err;
    },
  },
});

export default request;
