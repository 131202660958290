import request from "@/request";
import { GraphicalCode, Mobile } from "@/types/login";
import { ResultCommon } from "@/types/common";
import { RegisterEmailType, RegisterIponeType } from "@/types/register";
export const checkRegisterImageCode = (
  data: GraphicalCode
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/check/register/sms/verification/code",
    data,
  });
};
export const sendEmailCode = (
  data: RegisterEmailType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/register/email/code",
    data,
  });
};
export const registerEmail = (
  data: RegisterEmailType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/register/email",
    data,
  });
};
export const registerIphone = (
  data: RegisterIponeType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/register/mobile",
    data,
  });
};
export const sendIphoneCode = (data: Mobile): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/get/register/sms/verification/code",
    data,
  });
};

export const getSendCode = (data: GraphicalCode): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/register/sms/code",
    data,
  });
};
