import Cookies from "js-cookie";
export const getCookie = (value: string): string => {
  return Cookies.get(value);
};

export const setCookie = (name: string, value: string): void => {
  Cookies.set(name, value);
};

export const removeCookie = (value: string): void => {
  Cookies.remove(value);
};

export const removeLocalStorage = (value: string): void => {
  localStorage.removeItem(value);
};

//  设置localStorage
export const setLocalStorage = (name: string, value: unknown): void => {
  localStorage.setItem(name, JSON.stringify(value));
};
//  获取localStorage
export const getLocalStorage = (value: string): Record<string, unknown> => {
  const dataValue: string = localStorage.getItem(value);
  const data = JSON.parse(dataValue);
  return data;
};
