<template>
  <img src="../assets/mobile-images/logo.png" class="logo" />
</template>
<style lang="scss" scoped>
.logo {
  height: 1.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.42rem auto 1rem;
}
</style>
