import { downloadDecHub } from "@/api/download";
import { isGotoLogin, staticTotal } from "@/utils";
import { DownloadDecHubResultType } from "@/types/common";
export default {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    downloadDecHub() {
      if (!isGotoLogin()) {
        downloadDecHub().then((res: DownloadDecHubResultType) => {
          staticTotal("download");
          if (res.code == 0) {
            window.location.href = res.data.downloadUrl;
          }
        });
      }
    },
  },
  mutations: {},
};
