import Driver from "driver.js";
import store from "@/store";
import "driver.js/dist/driver.min.css";
import { ElMessageBox } from "element-plus";
import { getNoviceuidance, runSkipBoot } from "@/api/login";
import router from "@/router/index";
import i18n from "@/lang";
import { watch, computed } from "vue";
import { ResultCommon, guidanceType } from "@/types/common";
const language = computed(() => store.state.appLanguage);
const $t = i18n.global.t;
const activePageState = computed(() => store.state.activePageState);
const changeDialogState = () => {
  if (activePageState.value == false) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    document.getElementsByTagName("body")[0].classList.add("stop-scroll-top");
    store.commit("isShowActivePage", true);
  }
};

const setStep = () => {
  return [
    {
      element: ".home",
      popover: {
        title: " ",
        description:
          $t("novice_guidance.step_boxOne.before_step_name") +
          '<span style="color: #1CB3FF;font-weight: 600;">' +
          $t("novice_guidance.step_boxOne.step_name") +
          "</span>" +
          $t("novice_guidance.step_boxOne.after_step_name"),
      },
      onNext: () => {},
    },
    {
      element: ".privilege",
      popover: {
        title: " ",
        description:
          $t("novice_guidance.step_boxTwo.before_step_name") +
          '<span style="color: #1CB3FF;font-weight: 600;">' +
          $t("novice_guidance.step_boxTwo.step_name") +
          "</span>" +
          $t("novice_guidance.step_boxTwo.after_step_name"),
      },
      onNext: () => {
        router.push({
          path: "/help/faq",
        });
        store.getters.getDriverExp.defineSteps(driverStep);
        store.getters.getDriverExp.preventMove();
        setTimeout(() => {
          store.getters.getDriverExp.defineSteps(driverStep);
          store.getters.getDriverExp.start(2);
        }, 50);
      },
    },
    {
      element: ".help",
      popover: {
        title: " ",
        description:
          $t("novice_guidance.step_boxThree.before_step_name") +
          '<span style="color: #1CB3FF;font-weight: 600;">' +
          $t("novice_guidance.step_boxThree.step_name") +
          "</span>" +
          $t("novice_guidance.step_boxThree.after_step_name"),
      },
      onNext: (el: Driver.Element) => {
        store.getters.getDriverExp.preventMove();
        setTimeout(() => {
          store.getters.getDriverExp.defineSteps(driverStep);
          store.getters.getDriverExp.start(3);
        }, 50);
      },
    },
    {
      element: ".menu2",
      popover: {
        title: " ",
        description:
          $t("novice_guidance.step_boxFour.before_step_name") +
          '<span style="color: #1CB3FF;font-weight: 600;">' +
          $t("novice_guidance.step_boxFour.step_name") +
          "</span>" +
          $t("novice_guidance.step_boxFour.after_step_name"),
      },
      onNext: (el: Driver.Element) => {
        document
          .getElementsByTagName("body")[0]
          .classList.remove("stop-scroll-top");
        document
          .getElementsByTagName("body")[0]
          .classList.add("stop-scroll-bottom");
      },
    },
    {
      element: ".customerBtn",
      popover: {
        title: " ",
        description:
          $t("novice_guidance.step_boxFive.before_step_name") +
          '<span style="color: #1CB3FF;font-weight: 600;">' +
          $t("novice_guidance.step_boxFive.step_name") +
          "</span>" +
          $t("novice_guidance.step_boxFive.after_step_name"),
      },
      position: "left",
    },
  ];
};

let driverStep: any[] = setStep();

// 跳过新手引导
const skipNoviceBoot = () => {
  // console.error("关闭导航");
  // 关闭新手导航的回调，调用接口，修改本地状态、修改后台状态
  let guidance = store.state.guidanceData;
  runSkipBoot({
    id: guidance.id,
    status: 2,
  }).then((res: ResultCommon) => {
    store.dispatch("setDriverState", false);
    document
      .getElementsByTagName("body")[0]
      .classList.remove("stop-scroll-top");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("stop-scroll-bottom");
  });
};

// 查询是否开启新手引导
const isNoviceuidance = () => {
  getNoviceuidance({
    remindType: "novice_guidance",
    clientId: "webApp",
  }).then((res: null | guidanceType) => {
    if (res.code == 0 && res.data !== null) {
      store.commit("guidanceData", res.data);
      start();
    } else {
      changeDialogState();
    }
  });
};

// 新手引导弹窗按钮、样式
const start = () => {
  if (store.getters.getDriverState) return false;
  ElMessageBox.confirm(
    "<div>" +
      $t("novice_guidance.novice_guidance_home.before_name") +
      "<span style='color: #1CB3FF;'>" +
      store.state.userInfo.nickName +
      "</span> <br/>" +
      $t("novice_guidance.novice_guidance_home.after_name") +
      "</div>",
    $t("novice_guidance.novice_guidance_home.title"),
    {
      dangerouslyUseHTMLString: true,
      // center: true,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      cancelButtonText: $t("novice_guidance.novice_guidance_home.cancel_btn"),
      confirmButtonText: $t("novice_guidance.novice_guidance_home.confirm_btn"),
      showClose: false,
      customClass: "NoviceGuidance",
    }
  )
    .then((res) => {
      driverStep = setStep();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      document.getElementsByTagName("body")[0].classList.add("stop-scroll-top");
      store.dispatch(
        "setDriverExt",
        new Driver({
          doneBtnText: $t("novice_guidance.step_btn_over"),
          stageBackground: "#fff",
          nextBtnText: $t("novice_guidance.step_btn_next"),
          allowClose: false,
          keyboardControl: false,
          className: "scoped-class",
          onReset: (Element) => {
            skipNoviceBoot();
            changeDialogState();
          },
        })
      );
      store.dispatch("setDriverState", "true");
      store.getters.getDriverExp.defineSteps(driverStep);
      store.getters.getDriverExp.start();
    })
    .catch((res) => {
      skipNoviceBoot();
      changeDialogState();
    });
};

const destroyDriver = (isEsc: boolean) => {
  // 修改本地vuex数据（新手导航是否启用状态）
  store.getters.getDriverExp.reset();
};
export { isNoviceuidance, destroyDriver };
