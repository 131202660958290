interface ProductPriceType {
  calcType: number;
  currencyType: string;
  id: number;
  price: number;
  stepEnd: string;
  stepLevel: number;
  stepStart: string;
}
interface State {
  price: number;
  currentSurplusTime: number;
  priceList: ProductPriceType[];
}
export default {
  namespaced: true,
  state: {
    price: 0,
    currentSurplusTime: 0,
    priceList: [],
  },
  getters: {},
  actions: {},
  mutations: {
    setPrice(state: State, active: number) {
      state.price = active;
    },
    setCurrentSurplusTime(state: State, number: number) {
      state.currentSurplusTime = number;
    },
    setPageList(state: State, list: ProductPriceType[]) {
      state.priceList = list;
    },
  },
};
