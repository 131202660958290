// import crypto from 'crypto'
import crypto from "crypto";

const publicKey =
  "-----BEGIN PUBLIC KEY-----\n\
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC+efT0C1CU2uCqcGBluGWFBEoJKCrjnzny0+1LPekDAgRC1csrcgxDxbtp2kx6LSTdtk7+2Ryb+CL0E2L3B3AnjYjTGT7yVYuE6+WOQuadyrlTm05E0yYA1iKYFuWBUDGTDO5ofc4K0D5nCWBwd5uewV6VQLp1/1c/JJ4S41qqWwIDAQAB\n\
-----END PUBLIC KEY-----";
function publicEncryptLarge(data: string): string {
  const KEYSIZE =
    crypto.publicEncrypt(
      { key: publicKey, padding: crypto.constants.RSA_PKCS1_PADDING },
      Buffer.from("hello")
    ).length - 11;

  let result = Buffer.from("");
  let pos = 0;
  while (data.length - pos > KEYSIZE) {
    result = Buffer.concat([
      result,
      crypto.publicEncrypt(
        { key: publicKey, padding: crypto.constants.RSA_PKCS1_PADDING },
        Buffer.from(data)
      ),
    ]);
    pos += KEYSIZE;
  }
  result = Buffer.concat([
    result,
    crypto.publicEncrypt(
      { key: publicKey, padding: crypto.constants.RSA_PKCS1_PADDING },
      Buffer.from(data)
    ),
  ]);
  return result.toString("base64").replace(/\+/g, "(dec)");
}

export default {
  publicEncryptLarge,
};
