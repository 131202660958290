import request from "@/request";
import { ResultCommon, UUIDType } from "@/types/common";
import { UUIDData } from "@/types/statistical";
import CryptoJS from "crypto-js";
import { AES_KEY, AES_IV } from "@/request/config";
export const sendStatistical = (
  headerContent: string
): Promise<ResultCommon> => {
  return request.get({
    url: `../p.png?t=${new Date().getTime()}`,
    headers: {
      statistical: 1,
      bp: CryptoJS.enc.Base64.stringify(
        CryptoJS.AES.encrypt(headerContent, CryptoJS.enc.Utf8.parse(AES_KEY), {
          mode: CryptoJS.mode.CBC,
          iv: CryptoJS.enc.Utf8.parse(AES_IV),
          padding: CryptoJS.pad.Iso10126,
        }).ciphertext
      ),
      // CryptoJS.AES.encrypt(
      //   headerContent,
      //   CryptoJS.enc.Utf8.parse(AES_KEY),
      //   {
      //     mode: CryptoJS.mode.ECB,
      //     padding: CryptoJS.pad.ZeroPadding,
      //   }
      // ).toString(),
    },
  });
};
export const queryUUID = (data: UUIDData): Promise<UUIDType> => {
  return request.post({
    url: `/v-user/visitor/generate`,
    data,
  });
};
