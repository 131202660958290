import router from "@/router";
import store from "@/store";
import isMobile from "ismobilejs";
import { ElMessage } from "element-plus";
import CryptoJS from "crypto-js";

import { AES_KEY, AES_IV } from "@/request/config";
import {
  getCookie,
  setCookie,
  removeCookie,
  removeLocalStorage,
} from "@/utils/cookies";
import i18n from "@/lang";
import { sendStatistical, queryUUID } from "@/api/statistical";
export const isEmail = (value: string): boolean => {
  return /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(
    value
  );
};

export const isPhone = (value: string): boolean => {
  return /^1[3456789]\d{9}$/.test(value);
};
export const mobileGotoNext = (route: string) => {
  if (isMobile().any) {
    router.push({
      name: route,
    });
  }
};

export const isGotoLogin = () => {
  if (!getCookie("token")) {
    if (isMobile().any) {
      router.push({
        name: "login",
      });
    } else {
      store.commit("showLogin");
    }
    return true;
  }
  return false;
};

export const goToNext = (route: string) => {
  if (!getCookie("token")) {
    store.commit("showLogin");
  } else {
    router.push({
      name: route,
    });
  }
};

export const logout = () => {
  removeCookie("token");
  removeCookie("uid");
  removeLocalStorage("userInfo");
  store.commit("setIsToken", false);
  router.push({
    name: "home",
  });
};
export const isOnlyWebSite = () => {
  return ["localhost", "sandbox.virtueal.cn", "dec.virtueal.cn"].find(
    (i) => i === (document as any).domain
  );
};
export const zhEnConversion = (text: string): string => {
  let result = "";
  switch (text) {
    case "企业版":
      result = i18n.global.t("user_center.trial_version");
      break;
    case "专业版":
      result = i18n.global.t("user_center.major_version");
      break;
    default:
      result = i18n.global.t("privilege.free_version");
      break;
  }
  return result;
};

export const clipboardSuccess = () => {
  ElMessage.success(i18n.global.t("user_center.copy_success"));
};

export const clipboardError = () => {
  ElMessage.success(i18n.global.t("user_center.copy_fail"));
};
export const currencyMap = (type: string) => {
  return type === "CNY" ? "￥" : "$";
};
/**
 * AES解密
 * @param
 */
export const decodeAES = (txt: string) => {
  return new Promise((resolve, reject) => {
    try {
      let ending = CryptoJS.AES.decrypt(
        CryptoJS.enc.Base64.stringify(CryptoJS.enc.Base64.parse(txt)),
        CryptoJS.enc.Utf8.parse(AES_KEY),
        {
          mode: CryptoJS.mode.CBC,
          iv: CryptoJS.enc.Utf8.parse(AES_IV),
          padding: CryptoJS.pad.Iso10126,
        }
      )
        .toString(CryptoJS.enc.Utf8)
        .toString();
      if (ending.length) {
        resolve(1);
      } else {
        reject();
      }
    } catch (e) {
      reject();
    }
  });
};
/**
 * statisticalCookie
 * 设置数据统计所需cookie
 * @param key Cookie Key
 * @param value Cookie Value
 */
export const statisticalCookie = (key: string, value: string) => {
  switch (key) {
    case "cid":
      if (value) {
        let channels: string[] = (getCookie("cid_h") || "")
          .split(",")
          .filter((i) => !!i);
        let item = channels.find((i) => i === value);
        if (!item) {
          channels.push(value);
          setCookie("cid_h", channels.join());
        }
      }
      setCookie("cid", value || "");
      break;
    case "upi":
      // UserAgent Parse Info
      setCookie("upi", value);
      // UserAgent Parse MD5
      setCookie("upm", CryptoJS.MD5(value).toString().toUpperCase());

      break;
    default:
      setCookie(key, value || "");
      break;
  }
};
export const staticTotal = (ev?: string) => {
  if (!getCookie("uuid")) {
    queryUUID({
      platform: "webApp",
    }).then((res) => {
      setCookie("uuid", res.data);
      staticTotal(ev);
    });
    return false;
  }
  const sContent: Record<string, string> = {
    // 游客标识(UUID)
    UUID: getCookie("uuid"),
    // 用户标识(userId)
    userId: getCookie("uid"),
    // 业务版本(version)
    version: "",
    // 平台类型
    platform: getCookie("platform"),
    // 事件(UserEvent)
    event: ev || "visit",
    // 渠道(channelId)
    channelId: getCookie("cid"),
    // 浏览器标识 UserAgent Parser Info
    // userAgentParser: getCookie("upi"),
    // 浏览器标识2 MD5(UserAgent Parser Info)
    // userAgentParserMD5: getCookie("upm"),
    // 访问地址
    // url: to.url,
    // 来源入口
    // referrer: document.referrer,
    // 环境变量(ENV)
    // env: process.env.NODE_ENV == "development" ? "dev" : "prod",
  };
  let parseArr: string[] = [];
  "UUID platform version channelId event userId"
    .split(" ")
    .forEach((key: string) => {
      parseArr.push(sContent[key] || "0");
    });
  sendStatistical(parseArr.join("|"));
};
