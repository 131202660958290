const lang = {
  title: "DEC-Interconnected Real-timeRendering Data Engine",
  header: {
    login: "Sign in",
    signUp: "Sign Up",
    DECDow: "Download DEC",
    product: "Product Introduction",
    privilege: "Privilege Introduction",
    help: "Tutorial & Help Center",
    switchLanguage: "language",
    user_tutorial: "User Manual",
    company_address: "No.55, Banjieta Road, Chaoyang District, Beijing, China",
    copyright: "Copyright © Virtueal Co. Ltd. All Rights Reserved.",
    download: "Download",
  },
  login: {
    sign_in: "Sign in",
    email_error_text: "Please enter your email",
    password_error_text: "Please enter password",
    forgot_password: "Forgot password",
    login_in: "Sign in",
    terms_of_service:
      "By signing in, you agreed to our Unser Privacy Agreement and Terms of Service.",
    sign_up: "Sign Up",
    dear_dec_user: "Dear nickname, please choose your plan",
    sign_with_code: "OTP Sign in",
    you_agree_to: "By signing in,you agree to the ",
    reg_you_agree_to: "By registering, you agreed to our ",
    user_privacy: "《User Privacy Agreement 》",
    and: "and ",
    terms_of_service1: "《Terms of Service》",
    password_change_success: "Password change complete",
    cheack_email:
      "An email has been sent to your email address, please check your inbox and activate your account.",
    reg_success: "Congratulations, your account has been successfully created.",
    please_enter_email_phone: "Please enter your email/phone number",
    email_format_error: "incorrect email format",
    activation_succeeded: "Activation succeeded.",
    activation_failed: "Activation failed.",
    please_enter_the_account:
      "The account number is incorrect, please re-enter it again!",
    login_email_iphone: "Please enter your email",
    register_channel_code: "Please enter Invitation Code (Optional)",
    number_code_max6: "Please enter 6-digit SMS verification code",
    switch_account: "Switch account",
  },
  home: {
    core_funs: "Core Functions",
    core_funs_contents1_top:
      "Freely configure assets & Multi-person real-time collaboration",
    core_funs_contents1_center:
      "Users can freely configure the same set of digital assets between the DCC software and the real-time rendering engine, such as configuring dynamic assets on the DCC software side and static assets on the real-time rendering engine side, thereby optimizing and reducing the DCC software side to hardware resources dependency. Users can also rely on the real-time rendering engine to efficiently construct the terrain editing system.",
    core_funs_contents1_bom:
      "The underlying architecture of DEC can realize a revolutionary multi-person collaborative nonlinear production process.",

    core_funs_contents2_top:
      "DCC software side gives material properties & DEC Node makes engine special effects easier",
    core_funs_contents2_center:
      "DEC can assign the material of the real-time rendering engine to the Mesh object or any polygon in the DCC software, and the Mesh object in the DCC software can interact and collide with the particle system in the real-time rendering engine.",
    core_funs_contents2_bom:
      "DEC Node only needs limited resources in the DCC software to directly call the model in the real-time rendering engine. It can obtain the displacement, rotation, scaling and other values of any Transform in the model in real time, which is convenient for real-time rendering engine effects and DCC software objects without creating any blueprint.",
    title1: "Interconnected real-time",
    title2: "rendering",
    title3: "data engine",
    start_free: "Start Free",
    document:
      "DATA ENGINE CONNECT (DEC) is a 3D data engine tool independently developed by Virtueal. By breaking through the data barriers between traditional 3D software (DCC software) and real-time rendering engines, we realize real-time 3D data interconnectivity. We also greatly improve the efficiency and quality of producing 3D digital content using real-time rendering engine. Meanwhile, the underlying architecture of DEC enables future multi-person collaboration on non-linear productions.",
    tap1_button_text1: "Asset Allocation & Non-Linear Pipelines",
    tap1_button_text2: "Properties and Effects",
    tap1_propertie_effects: "Properties and effects",
    tap2_button_text1: "Video output",
    tap2_button_text2: "Assets edit",
    core_funs_contents3_top:
      "Browse rendering effects in real time & exclusive Redraw function",
    core_funs_contents3_center:
      "In the window of the DCC software, you can see the rendering effect of the engine in real time, use the Playblast function to directly output the video, and also use the renderer carried by DEC to render high-quality video and sequence pictures.",
    core_funs_contents3_bom:
      "DEC's unique Redraw function can perfectly solve the problems of black shadow and blur caused by high-speed movement of characters.",
    core_funs_contents4_top:
      "Render to film without importing and exporting animation files (FBX and ABC)",
    core_funs_contents4_center:
      " DEC supports operations as model editing, animation, lighting configuration while displaying real-time rendering effects in the DCC preview window.",
    core_funs_contents4_bom:
      "DEC supports any type of deformer and custom deformer in the DCC (Digital Content Creation) software, allowing users to set up complex bindings. DEC also retains the core advantages of digital content creation tools in asset production and animation production. Films can be rendered without importing and exporting animation files (FBX and ABC).",
    learn_more: "More about the DEC",
    partner: "Partners",
    tap_up: "Up",
    tap_more: "More",
  },
  register: {
    email_register: "Email registration",
    register: "Sign Up",
    terms_of_service: "By registering, you agreed to our ",
    login_to: "Already have an account? go to ",
    set_register_password: "Please enter password",
  },
  faq: {
    how_to_use: "How to use DEC",
    how_to_use_doc:
      "Please click the [Download DEC] button on the official website to download the DEC Hub installer, and complete the installation process after downloading. <br />After starting DEC Hub, log in with your account. After logging in, click the [Install DEC] button under the [DEC Service] tab to install DEC. After the installation is successful, click the [Start DEC] button to use the free version of the service. <br />If you have purchased the Professional Edition service and need to activate it, please refer to [How to Become a Professional Edition User].<br />Please make sure that Maya 2016 and above is installed locally before installation.",
    trird_party: "How to sign in from a third-party service",
    trird_party_doc: "Please use email to sign up/sign in.",
    remove_watermark: "How to remove the watermark",
    remove_watermark_doc:
      "Watermark is turned off for [Pro Edition] or [Enterpreise Edition] users",
    pro_user_service: "How to become a pro user and activate the service",
    pro_user_service_doc:
      "Purchase the Pro Edition membership for full service.Click the [Purchase Service] button on the Official Website - Privilege Introduction Page - Professional Edition Package, select the appropriate package and quantity in the pop-up window, and complete the payment process.After the initial purchase, you need to activate the service on DEC Hub. <br />Activate your Pro Edition as follows: Click the [Activate Service] button, select the number of devices to be activated, and deduct the number of service months after confirmation. If you have any doubts about the deduction, please refer to [How to Deduct Service Time for Professional Edition Services]. ",
    company_user: "How to become an enterprise user",
    company_user_doc:
      "Enterprise Edition is a customized service we provide.If you are interested, you can click on the [Contact Us] button on our official website - privilege introduction page - enterprise version package. After filling in required information, we will arrange for staff to contact you in time. You can also contact us by clicking the [Contact Us] button on the Tutorial·Help Center page.",
    pro_service_time: "How to deduct service time",
    pro_service_time_doc:
      "The Pro service will be charged uniformly at 0:00 on the 1st of each month according to the number of devices you use in [Device Management]. The monthly service corresponding to the number of devices will be deducted each time. If your subscription balance is insufficient, there will be a prompt. Please renew for full service.<br /> For new users, the first deduction starts timing when the DEC service is activated, and the number of months to be deducted this month will be calculated.<br /> Note: After activating the service or modifying the number of devices used on the last day of each month, the service for the entire month of the next month will be deducted, and this operation will not be repeated at 0:00 the next day.",
    set_month: "What does setting Daily Authorized Devices for this month do?",
    set_month_doc:
      'This is a setting item for team collaboration on a local area network and online device management.<br /> You can edit this value to meet the needs of the local area network team to obtain the professional version of the service when there is no external network.<br /> "Number of authorized devices per day" refers to the number of devices that can be online at the same time on the same day. If the number of devices that have been set cannot meet the needs of the team, you can modify the value to increase the number of devices for the team to use.',
    not_wifi: "How to log in without network/LAN",
    not_wifi_doc:
      "If your computer cannot connect to the external network and you cannot log in to the DEC Hub with your account, try our local area network login service.<br /> You need at least one computer that can connect to the external network as an administrator account. Log in to the [Device Management] tab to get the login address of the local area network. Please manually distribute it to your team members.<br /> LAN users select [LAN login] when logging in, enter the address, and then use the DEC service.",
    max_number:
      "LAN login prompts that LAN authorized devices have reached the upper limit",
    max_number_doc:
      'This prompt appears when your concurrent online user number reaches the maximum. Please contact your team administrator to modify the value of "daily authorized devices this month".',
    how_to_invoice: "How to Invoice",
    how_to_invoice_doc:
      "The online function is still under development and will be launched as soon as possible. If you need to issue an invoice urgently, you can get our contact information in the Tutorial·Help Center-Contact Us section, and we will provide you with relevant services as soon as possible",
    contact_us: "How to contact us",
    contact_us_doc:
      "Contact our staff by clicking [Contact Us] on the Tutorial·Help Center page, or get our contact information at our website's footer.",
    company_details:
      "Set up in 2013, Virtueal is a top digital content production technology solutions company in China. Since its establishment, it has been committed to providing technology-driven solutions for entertainment content production, including the development and upgrade of film and television production technology, real-time rendering of animation production processes, the establishment of automated management systems in film and television industrial production, virtual idols and digital assets, etc.",
    phone: "Phone",
    email: "Email",
    address: "Address",
    nickname_place: "Please enter nickname",
  },
  privilege: {
    title: "visualizes your imagination",
    download_text: "Download DEC",
    free_version: "Free Edition",
    can_enjoy: "Services you can enjoy",
    real_time_preview: "Real-time preview",
    maya_open_ue: "Use UE on Maya side",
    watermark: "Watermark",
    without_watermark: "Without watermark",
    maya_limited: "Triangle count of MAYA scene is limited to 100,000",
    maya_not_limited: "No limit of triangle count of MAYA scene.",
    not_use: "No rendering",
    max_size: "Max Renderable Size：2k",
    max_size1: "Max Renderable Size：Unlimited resolution range",
    maya_objects_ue: "Referencing and instancing UE objects in MAYA.",
    contact_us_message: "Please contact our team for more information.",
    free_trial: "Free trial",
    purchase_service: "Purchase service",
    contact_us: "Contact us",
    min_number: "AS LOW AS $",
    yuan_day: " / day",
    maya_output: "Support super sampling output in MAYA.",
    feature_comparision: "Feature Comparision",
    classify: "Classify",
    authorized: "Authorized",
    maximum_range: "Maximum resolution range 2k",
    not_range: "Unlimited resolution range",
    render: "Render",
    render_options: "Movie Render Queue",
    render_options_config: "Render options are storable.",
    none: "None",
    controls_blue_ui: "Controls, Blueprints, Technology, UI",
    maya_scene_file:
      "Loading specific UE level map when loading MAYA scene file.",
    maya_edit_mo: "Editable mesh on MAYA side",
    maya_import_ue: "Import current scene from MAYA to UE",
    maya_cache: "Support arbitary MAYA mesh cache",
    maya_display_only: "For MAYA objects, display wireframe only, selectable",
    maya_modifier: "Support any modifier of MAYA",
    maya_controller: "Support any controller of MAYA.",
    all_ue_materials: "Support all UE materials of UE.",
    maya_preview_ue_materials: "Explore materials of UE project inside MAYA.",
    maya_meshes_ue: "Assign materials of UE to meshes of MAYA.",
    maya_controllables:
      "Properties of materials of UE are controllable in MAYA, by linking, keyframing, editing.",
    maya_replace_pic: "Textures of UE are changeable inside MAYA.",
    maya_udim_pic: "Support for UDIM Textures.",
    ue_replace_maya: "UE material properties can override MAYA properties.",
    maya_cpu: "Support MAYA object to UE GPU particle collision",
    maya_ue_pz: "Support physical collisions between MAYA objects & UE objects",
    mayas_default_point:
      "Support MAYA's default ambient, directional, point, spot light sources",
    maya_light: "Support MAYA's area light sources",
    maya_led_params:
      "Display UE's light sources parameters(color, intensity, light group) in MAYA.",
    maya_led_display_params:
      "Display UE's light sources parameters(other) in MAYA.",
    maya_camera: "Display UE's camera properties(Depth of field) in MAYA.",
    maya_camera_other: "Display UE's camera properties(other) in MAYA.",
    maya_ue_position: "Align position of MAYA camera to UE camera.",
    maya_animation: "Support keying any type of animation in MAYA.",
    maya_ue_with: "Synchronize MAYA's timeline with UE's SeqUEncer.",
    maya_side: "Start the UE project on the MAYA side",
    model: "Model",
    maya_ys: "Support arbitary cloth solvers in MAYA.",
    ue_426: "UE 4.26",
    ue_427: "UE 4.27",
    ue_50: "UE 5.0",
    maya_versions: "MAYA Version Support",
    ue_versions: "UE Version Support",
    side: "Window",
    animation: "Animation",
    camera: "Video camera",
    led: "Stage lighting",
    cpu: "Physics",
    materials: "Materials",
    modifier: "Bind",
    add_engine: "AddEngine Transform Delegate",
    maya_2017: "Maya2017",
    maya_2018: "Maya2018",
    maya_2019: "Maya2019",
    maya_2020: "Maya2020",
    maya_2022: "Maya2022",
    maya_2023: "Maya2023",
    movie_render: "Movie RenderQueue（MIRROR）",
    free_plate_form_use: "Only support one own device",
    pro_plate_form_use: "Support same LAN devices",
    enterprise_plate_form_use: "Unlimited support for the same server devices",
    detail: "Learn more",
    pay_error:
      "Payment is abnormal, please check if your payment is successful",
  },
  forgot_password: {
    change_password: "Change Password",
    enter_email: "Please enter the email",
    enter_password: "Please enter a new password",
    again_enter_password: "Please enter the new password again",
    next_step: "Next step",
    back_to_login: "Back to login",
    sent_successfully: " Sent successfully",
    has_password_url:
      "The password modification link has been sent to your *** email, you can confirm the modification through the content of the email",
    view_now: "View now",
    re_enter_email: "Re-enter email",
    login_in_now: "Log in now",
    reset_password: "Reset Password",
    reset_password_massage:
      "We have received your reqUEst to reset your password, please click the button below to reset it.",
    confirm: "Sure",
    url_invalid_text:
      "The change password link has expired, please re-obtain the change password link",
    emails_change: "Modify the password with the email",
    use_iphone_change_password: "Modify the password with the mobile phone",
  },
  user_center: {
    major_version: "Pro",
    company_version: "Enterprise",
    trial_version: "Trial version",
    account_info: "Account information",
    order_info: "Order information",
    change_password: "Change Password",
    set_password: "Set password",
    quit: "Quit",
    personal_info: "Personal information",
    nick_name: "Nickname:",
    email: "Mail:",
    iphone: "Mobile phone:",
    major_version_arrears: "Pro: n months in arrears",
    major_version_service: "Pro: Service validity period remaining * months",
    company_version_service: "Service validity period remaining",
    top_up: "Top up",
    renewal: "Renewal",
    service_months: "Service validity period remaining",
    integral: "Points",
    Exchange: "Rewards",
    integral_rule:
      "You can earn points by inviting friends/purchasing services, etc. The points can be redeemed for Free Trail/Pro services in the Rewards.You can go to the Dec Rewards to learn about the specific rules for earning points and redeemable products.",
    invitation_code: "Invitation Code",
    invitation_url: "Copy Link",
    order_number: "Order number",
    transaction_order_number: "Transaction order number",
    payment_time: "Payment time",
    service_name: "Service name",
    amount: "Amount",
    prev_page: "Previous page",
    next_page: "Next page",
    submit: "Submit",
    submit_success: "Submission successful",
    change_password_url_text:
      "We have sent an email with a confirmation link to your email address XXX. In order to reset your password, please click the confirmation link.",
    view_now: "View now",
    password_complate: "Password change complete",
    confirm: "Confirm",
    please_select_package:
      "Dear DEC users-323 users, please select the package you want to purchase",
    choose_package: "Choose a Package：",
    purchase_month_1: "Purchase 1 month",
    purchase_month_6: "Purchase 6 month",
    purchase_month_12: "Purchase 12 month",
    save_195: "Save $195",
    save_533: "Save $593",
    payment_method: "Payment method",
    wechat: "WeChat",
    api_pay: "AliPay",
    paypal: "PayPal",
    purchase_agreement0: "By purchasing, you agree to the ",
    purchase_agreement1: "Renew Service Agreement ",
    purchase_agreement2:
      "and agree to open the Pro service according to the agreement",
    money: "$",
    wechat_code_payment: "WeChat scan code payment",
    payment_abnormal:
      "Payment is abnormal, please check if your payment is successful",
    use_other_payment: "Use other payment channels",
    payment_number_text: "change quantity",
    payment_success: "Payment successful",
    dec_success_payment:
      "Thank you for successfully purchasing DEC Professional Edition service, which is valid for n months. The effective time starts from the time of logging in and activating the MAYA plug-in, and it will be calculated according to natural days.",
    contact_us: "Contact us",
    video: "Video",
    base_info:
      "In order to provide more professional enterprise services, please take a few minutes to fill in the basic information",
    company_name: "Company name :",
    please_enter_company_name: "Please enter your company name",
    website_address: "Official website address:",
    please_enter_address_url:
      "Please enter your company's official website address",
    headquarters_location: "Headquarters location:",
    please_enter_company_headquarters: "Please enter your company headquarters",
    team_software: "The size of the team using the software:",
    please_team_software: "Please select",
    team_people: "1~20 people",
    main_bus_multiple: "Main business (multiple choices):",
    animated_film: "Animated film",
    animated_drama: "Animated drama",
    vfx: "VFX",
    other: "Other",
    maya_vresion: "MAYA version (multiple choices):",
    not_use_maya: "Not using MAYA",
    ue_version: "UE version (multiple choices):",
    version_before: "Versions before {version}",
    not_use_ue: "Not using UE",
    internally_version: "Internally compiled version",
    renderer_used: "Renderer used (multiple choices):",
    arnold: "Arnold",
    red_shift: "Red Shift",
    vray: "Vray",
    renderman: "Renderman",
    real_time_rendering: "Real-time rendering engine used (multiple choices):",
    none: "None",
    did_you_use_dec: "Did you use DEC/MIRROR (single choice):",
    used: "Used",
    version: "Version",
    no_used: "No prior experience",
    how_know: "How did you hear about DEC/MIRROR (Select all that apply):",
    friend_recommend: "Friends recommendation",
    chinese_video: "Chinese video websites (Tencent Video, Bilibili etc.)",
    other_video: "Other video sites (Youtube, Vimeo, etc.)",
    public_account: "VirtUEal WeChat public account",
    official_website: "VirtUEal/DEC official website",
    most_interested:
      "You are most interested in DEC/mirror functions (multiple choices):",
    viewport_2: "Viewport 2.0 real-time preview rendering effect",
    complex_animation: "Real-time rendering of complex animations",
    click_to_push: "Click to push MAYA assets into the engine",
    playblast_output: "Playblast real-time output",
    free_allocation: "Free allocation of MAYA and UE4 assets",
    ue_large_scenes: "Efficient production of UE large scenes",
    prefered_title: "Prefered title",
    please_enter_name: "Please enter your name",
    your_phone_and_mobile: "Your phone/mobile number:",
    please_your_phone_and_mobile: "Please enter your phone/mobile number",
    your_job_title: "Your job title :",
    your_email: "Your email :",
    please_enter_job: "Please enter your job title",
    please_enter_email: "Please enter your email",
    special_info_remark: "Special information remarks:",
    leave_your_remark: "Leave your remarks here",
    contact_you:
      "We will reply as soon as possible and arrange corresponding personnel to contact you",
    cancel: "Cancel",
    faq: "FAQ",
    video_tutorial: "Video tutorial",
    phone: "Phone",
    not_order_info: "No order information yet...",
    renew: "Renew",
    service_valid: "Service validity period remaining",
    months: "Months",
    price: "Price",
    remark: "Remark",
    please_nickname: "Please enter nickname",
    edit_success: "Modification succeeded.",
    please_code: "Please enter the OTP code",
    complete: "complete",
    phone_change_password: "Modify the password with the mobile phone",
    send_code: "Send code",
    password_none: "Please enter password",
    code_none: "SMS verification code cannot be empty.",
    confirm_none: "Enter password",
    confirm2_none: "Please enter the new password again",
    use_r_code: "Scan to pay with Wechat",
    order_ing: "Order Processing",
    order_error: "Order Failed",
    order_overtime: "your order process session has expired",
    device_months: "devices/month",
    device_month: "1~9 <br />devices/month",
    device_month_10_49: "10~49 <br />devices/month",
    device_month_50: "50+ <br />devices/month",
    current_price: "Current Unit Price",
    v_code_velid: "V code",
    please_v_code: "Please enter the V code",
    please_v_code1: "Please enter the V code",
    use_v_code: "Apply",
    canel: "No",
    quantity: "Quantity",
    device_month1: "devices/month",
    actual_amount: "Amount",
    please_iphone: "Please enter your phone number",
    device_month_membership: "devices/month membership service",
    quit_login: "Are you sure you want to sign out",
    exit_success: "exit successfully",
    change_email: "Change email",
    discount: "Discount: save ",
    limit_number: "limited Purchase Quantity",
    verification_succeeded: "Verification succeeded! ",
    payment_succeeeded:
      "You have successfully purchased the DEC Pro Edition service of XXX devices per month, please download and install the DEC Hub first. You can enjoy the membership service after sign-in and activating it in accordance with the instructions.",
    view_the_order: "View the order",
    help1: "If you have any questions, please go to",
    help2: "Support",
    help3: "Get answers from FAQ or contact us for more information.",
    people: "people",
    rewards_service_name: "Service",
    rewards_service_time: "Service time",
    rewards_service_amount: "Dec Points", //变动
    rewards_give_ty: "Gift {supplyInfo} device(s)/month(s) service",
    rewards_add_pc: "Increase {supplyInfo} authorized device(s)",
    rewards_receive_ty: "Received {supplyInfo} month(s) service",
    rewards_recharge_ty: "Purchase {supplyInfo} device(s)/month(s) service",
    rewards_auto_deduct_pc: "Renew {supplyInfo} device(s)/month(s) this month",
    rewards_active_pc: "Activate {supplyInfo} authorized device(s",
    rewards_open_company_day: "Open {supplyInfo} day(s) Trail Version",

    rewards_change_company_day:
      "(Bonus)Redeem {supplyInfo} day(s) Trail Version",
    rewards_change_ty: "(Bonus)Redeem {supplyInfo} device(s)/month(s)",

    rewards_invite_register_user: "Invite {content} to sign up",
    rewards_invited_register_user: "Invited by {content} to sign up",
    rewards_invited_first_order: "new user {content} invited make payment",
    rewards_system_invited_register_user:
      "sign up with {content} invitation link",

    rewards_change_tm_consume: "Redeem for {supplyInfo} days Trail Version",
    rewards_change_ty_consume: "Redeem for {supplyInfo} devices/months",

    rewards_register_user: "{content}sign up an account",
    not_rewards_info: "No point record information",
    copy_success: "Copy succeeded",
    copy_fail: "Copy failed",
    per_center: "Personal Center",
    order_list: "Order list",
    account_list: "Bill list",
  },
  not_language: {
    ipone_not_none: "Please enter your phone number",
  },
  user_tut: {
    web_use: "Website Features",
    dec_install: "Installation",
    dec_functions: "DEC Features",
  },
  v_code: {
    normal_price: "Normal Price",
    discount: "save {number}",
    per_device: "{number} per device",
    discount_button_text: "Add V promo code",
    buy_limit:
      "You have reached the purchase limit (X Devices/Months) for this V code.",
  },
  us: {
    base_info:
      "In order to provide more professional enterprise services, please take a few minutes to fill in the basic information",
    company_name: "Please enter your company name",
    company_url: "Please enter your company's official website address",
    company_address: "Please enter your company headquarters",
    company_teams: "Please select the size of your team using the software",
    main_business: "Please select your main business",
    maya_version: "Please select your Maya Version",
    ue_version: "Please select your UE Version",
    select_use: "Please select your Renderer",
    check_use: "Please select your Real-time Rendering Engine",
    nown_dec_mirror: "How did you hear about DEC/MIRROR",
    dec_mirror: "You are most interested in DEC/mirror functions？",
    what: "Please enter your name",
  },
  gift: {
    gift_service: "Gift Service",
    please_input_gift_account: "Please enter account you gift to",
    vlid_user: "Please enter your verification code to verify your identity",
    gift_account: "Account you gift to：",
    please_input_gift_number:
      "Please enter the number of devices/month you gift",
    gift_success: "Gift Sending Successful",
    authentication: "Authentication",
    tips: "The OTP code will be sent to the mobile phone number/email that is bound to the account you gift to, please contact the owner to check the verification code",
    balance:
      "Balance remaining N devices/months. It is not enough to gift X months this time.",
    balance_title: "Insufficient Balance",
    back: "Back",
    limit:
      "The system maintenance time is from 23:00 on the last day of each month to 02:00 on the next day. During this time, you can not increase devices number or gift services.",
    limit_title: "Account locked",
  },
  information: {
    order: "Order",
    account: "Bill",
    balance: "Balance",
    integral: "Dec Points",
    termOfValidity: "Reward available until",
    device_months: "Devices/Month",
    topUp: "Renew",
    gift: "Gift",
    not_bill_info: "No results matching your search were found...",
    bill: {
      subHistorySerialNo: "Serial Number",
      createTime: "Service Start Time",
      tradeType: "Service Type",
      content: "Service Details",
      change: "Balance Change",
      afterSubBalance: "Balance",
      keyword: "Search By Name",
    },
    searchTime: {
      startTime: "Start Time",
      endTime: "End Time",
    },
    searchType: {
      consume: "Devices Increase",
      recharge: "Service Purchase",
      active: "Service Activated",
      give: "Service Give Away",
      receive: "Service Received",
      auto_deduct: "Automatic Deduction",
      all: "All Types",
      increase: "Balance Increased",
      diminished: "Balance Decreased",
      change: "Rewards",
    },
    keyword: "Search",
    serviceContent: {
      consume: {
        before: "Increase",
        after: "Devices/Month",
      },
      recharge: {
        before: "Renew",
        after: "Devices/Month",
      },
      active: {
        before: "Activate",
        after: "Devices/Month",
      },
      give: {
        before: "Gift",
        after: "Devices/Month",
      },
      receive: {
        before: "gift you",
        after: "Devices/Month",
      },
      auto_deduct: {
        before: "Deduct",
        after: "Devices/Month",
      },
      change: {
        before: "Redeem",
        after: "device(s)/month(s)",
      },
    },
  },
  novice_guidance: {
    novice_guidance_home: {
      title: "Hello！",
      before_name: "Dear",
      after_name:
        "welcome to the official website of DEC. Do you need me to help you briefly introduce the functions of each section on the official website?",
      cancel_btn: "Cancel",
      confirm_btn: "Confirm",
    },
    step_boxOne: {
      before_step_name: "On the ",
      step_name: '"Product Introduction"',
      after_step_name:
        " page, you can browse core features and application scenarios of DEC.",
    },
    step_boxTwo: {
      before_step_name: "On the ",
      step_name: '"Privilege Introduction"',
      after_step_name:
        " page, you can learn about the differences between our different editions of services and purchase corresponding services.",
    },
    step_boxThree: {
      before_step_name:
        'If you encounter any problems, please refer to "FAQ" in the ',
      step_name: '"Tutorial & Help Center"',
      after_step_name: "",
    },
    step_boxFour: {
      before_step_name: "",
      step_name: '"User manual"',
      after_step_name: " can also help you solve the problems.",
    },
    step_boxFive: {
      before_step_name:
        "If these still can't solve your problem, you can get in touch with our customer service staff in the lower right corner of the official website.",
      step_name: "",
      after_step_name: "",
    },
    step_btn_next: "Next",
    step_btn_over: "Finish",
    step_btn_skip: "Skip",
  },
  mobile: {
    download_to_pc: "Please download on PC",
    donwload_link: "Download link",
    copy_link: "Copy link",
    close: "close",
  },
  topic: {
    your_friend: "Your friend {userName} has invited you to try DEC",
    reg_to_points: "Register to get points and redeem a",
    free_7day: "Free 7-day Trail.",
    content1: "Freely configure assets; multi-person real-time collaboration",
    content2:
      "Browse rendering effects in real time; exclusive Redraw function",
    content3:
      "DCC software side gives material properties; DEC Node makes engine special effects easier.",
    content4:
      "Render to film without importing and exporting animation files (FBX and ABC)",
    sign_up: "Sign Up Now",
    features: "DEC features",
  },
  pointsMall: {
    titleName: "Rewards",
    score: "Point(s)",
    Exchange: "Redeem",
    successfully: "Successfully Redeemed",
    trialVersion: "Trail Version",
    day: "day(s) Trail Version",
    deviceMonths: "device(s)/month(s)",
    how: "How to earn Dec points",
    time: "Discontinued after {offTime} days",
    btn: "Confirm",
    dialogText:
      "{goodprice} Dec Point need to be redeemed for {goodname} items,  your points balance is lower than the amount required.",
    fast: "How to earn more points?",
    register: "Sign up for points",
    text: "In the personal center, you can view your invitation code, generate your invitation link and send it to your friends. After your friend registers a DEC account through your invitation link, both you and your friends can earn points. Points can also be earned by purchasing DEC Pro Version Services or increasing Number of Daily Authorized Device.",
    fastName: [
      "Invite friend",
      "Purchase service",
      "Increase number of authorized device",
      "friend you invite make their payment",
    ],
    fastSocre: [
      "points Each friend you invite",
      "points Each device/month",
      "points Each device",
      "points Friend's First Payment",
    ],
    fastNum: ["Unlimited", "Only for one-time"],
    registertName: ["Invited Sign Up", "Normal Sign Up"],
    registerSocre: "points",
  },
  active_page: {
    title_left: "Earn points,",
    title_right: "get rewards!",
    button: "Join Now",
    redeem: "Redeem",
    active_type: {
      register_title: "Register now to get",
      register_centent: "a Free 7-day Trial",
      invitation_title: "Invite new friends to ",
      invitation_centent: "get more Trail days",
      purchase_title: "Friend’s First Payment",
      purchase_centent: " reward inviter A Month Trial",
    },
    active_type_m: {
      register_title: "",
      register_centent: "Register now to get a Free 7-day Trial",
      invitation_title: "",
      invitation_centent: "Invite new friends to get more Trail days",
      purchase_title: "",
      purchase_centent: "Friend’s First Payment reward inviter A Month Trial",
    },
    how_get: {
      before: "In the ",
      name: "personal center",
      after:
        ", you can view your invitation code, generate your invitation link and send it to your friends. After your friend registers a DEC account through your invitation link, both you and your friends can earn points.",
    },
    where_get: {
      title: "How to redeem rewards?",
      before: "In the personal ",
      name: "center/account management",
      after:
        ", click 'Rewards' to enter the Rewards. You also can click 'Rewards' on the official website to enter the Rewards.",
    },
    how_free: "How to try out all the features of DEC for Free?",
    quick_view: "View Now",
    free: "Free Limited",
  },
  videoInfo: {
    backToList: "Back to Playlist",
    moreVideo: "View More",
  },
};

export default {
  lang,
};
