import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { isOnlyWebSite, statisticalCookie, staticTotal } from "@/utils";
const routes: RouteRecordRaw[] = [
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
  },
  // 首页
  {
    path: "/",
    name: "home",
    meta: {
      mainMenuPosition: "home",
    },
    component: () => import("../views/Home/Index.vue"),
  },
  // 特权介绍
  {
    path: "/privilege",
    name: "privilege",
    meta: {
      mainMenuPosition: "privilege",
    },
    component: () => import("../views/Privilege/Index.vue"),
  },
  {
    path: "/help",
    redirect: "/help/faq",
    children: [
      {
        path: "/help/faq",
        name: "helpFAQ",
        meta: {
          mainMenuPosition: "help",
        },
        component: () => import("@/views/FAQ/Index.vue"),
      },
      {
        path: "/help/books",
        redirect: "/help/books/web-use",
      },
      {
        path: "/help/books/:type/:position?",
        name: "helpBooks",
        meta: {
          mainMenuPosition: "helpBooks",
        },
        component: () => import("@/views/Books/Index.vue"),
      },
      {
        path: "/help/contactUs",
        name: "helpContactUs",
        meta: {
          mainMenuPosition: "help",
        },
        component: () => import("@/views/ContactUs/Index.vue"),
      },
      {
        path: "/help/video",
        name: "helpVideo",
        meta: {
          mainMenuPosition: "help",
        },
        component: () => import("@/views/Video/Index.vue"),
      },
      {
        path: "/help/video/:id",
        name: "helpVideoInfo",
        meta: {
          mainMenuPosition: "helpVideo",
        },
        component: () => import("@/views/Video/Info.vue"),
      },
    ],
  },
  // 登录
  {
    path: "/userCenter",
    redirect: "/userCenter/personal",
    children: [
      {
        path: "/userCenter/personal",
        name: "userCenterPersonal",
        meta: {
          mainMenuPosition: "userCenter",
        },
        component: () => import("@/views/UserCenter/Personal/Index.vue"),
      },
      {
        path: "/userCenter/account",
        name: "userCenterAccount",
        meta: {
          mainMenuPosition: "userCenter",
        },
        component: () => import("@/views/UserCenter/Account/Index.vue"),
      },
      {
        path: "/userCenter/account/order",
        name: "userCenterAccountOrder",
        meta: {
          mainMenuPosition: "userCenter",
        },
        component: () => import("@/views/UserCenter/Account/ItemOrder.vue"),
      },
      {
        path: "/userCenter/account/bill",
        name: "userCenterAccountBill",
        meta: {
          mainMenuPosition: "userCenter",
        },
        component: () => import("@/views/UserCenter/Account/ItemBill.vue"),
      },
      {
        path: "/userCenter/account/rewards",
        name: "userCenterAccountRewards",
        meta: {
          mainMenuPosition: "userCenter",
        },
        component: () => import("@/views/UserCenter/Account/ItemRewards.vue"),
      },
      {
        path: "/userCenter/amend-password",
        name: "amendPassword",
        meta: {
          pageType: "change",
        },
        component: () => import("@/views/SetPassword/Index.vue"),
      },
      // 忘记密码
      {
        path: "/userCenter/forget-password",
        name: "forgetPassword",
        meta: {
          pageType: "forget",
        },
        component: () => import("@/views/SetPassword/Index.vue"),
      },
    ],
  },
  // 登录
  {
    path: "/login",
    name: "login",
    meta: {
      mainMenuPosition: "login",
    },
    component: () => import("@/components/Login/Index.vue"),
  },
  // 注册
  {
    path: "/register",
    name: "register",
    meta: {
      mainMenuPosition: "register",
    },
    component: () => import("@/components/Register/Index.vue"),
  },
  // 积分商城
  {
    path: "/pointsMall",
    name: "pointsMall",
    meta: {
      mainMenuPosition: "pointsMall",
    },
    component: () => import("@/views/PointsMall/Index.vue"),
  },
  // 积分商城 移动端如何获取积分
  {
    path: "/rewards/article",
    name: "rewards/article",
    meta: {
      mainMenuPosition: "article",
    },
    component: () => import("@/views/PointsMall/rewards/article.vue"),
  },
  // 积分商城 落地页
  {
    path: "/topic/account",
    name: "topicAccount",
    meta: {
      mainMenuPosition: "topic",
    },
    component: () => import("@/views/Topic/Account/Index.vue"),
  },
  // 新用户活动页
  {
    path: "/activePage/newUser",
    name: "newUser",
    meta: {
      mainMenuPosition: "newUser",
    },
    component: () => import("@/views/ActivePage/NewUser.vue"),
  },
  // //教程  帮助中心
  // {
  //     path: "/support-center",
  //     name: "SupportCenter",
  //     component: () => import("../view/SupportCenter/index.vue"),
  // },
  // //用户手册
  // {
  //     path: "/user-tutorial",
  //     name: "user-tutorial",
  //     component: () => import("../view/SupportCenter/UserTutorial.vue"),
  // },
  // //
  // {
  //     path: "/information",
  //     name: "information",
  //     component: () => import("../view/information/index.vue"),
  // },
  // 修改密码

  // // 忘记密码  邮件
  // {
  //     path: "/forget-passwordConfirm",
  //     name: "forgetPasswordConfirm",
  //     component: () => import("../components/forgetPasswordConfirm.vue"),
  // },
  // // 修改密码  邮件
  // {
  //     path: "/change-passwordConfirm",
  //     name: "changePasswordConfirm",
  //     component: () => import("../components/changePasswordConfirm.vue"),
  // },
  // 支付结果
  {
    path: "/pay-ment",
    name: "payMent",
    component: () => import("../views/Privilege/PayMent.vue"),
  },
  {
    path: "/user-privacy",
    name: "agreement",
    component: () => import("@/views/UserPrivacy/Agreement.vue"),
  },
  {
    path: "/service-clause",
    name: "serviceClause",
    component: () => import("@/views/UserPrivacy/ServiceClause.vue"),
  },
  {
    path: "/purchase-services",
    name: "purchase-services",
    meta: {
      mainMenuPosition: "mobilePay",
    },
    component: () => import("@/views/Privilege/MobilePay.vue"),
  },
  {
    path: "/constact-us",
    name: "constactUs",
    meta: {
      mainMenuPosition: "constactUs",
    },
    component: () => import("../views/Privilege/constactUs.vue"),
  },
  // {
  //     path: "/activate-account",
  //     name: "activateAccount",
  //     component: () => import("../view/activate/activateAccount.vue"),
  // },
  {
    path: "/give-service",
    name: "giveService",
    meta: {
      mainMenuPosition: "gift",
    },
    component: () => import("../views/Gift/Index.vue"),
  },
  {
    path: "/warning",
    name: "warning",
    component: () => import("../views/Warning/Index.vue"),
  },
  {
    path: "/testing",
    name: "testing",
    component: () => import("../views/TEST/Index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(), //路由模式
  routes, //路由规则
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});
router.beforeEach((to: any, from: any, next: any) => {
  statisticalCookie("cid", to.query.ch);
  if (!isOnlyWebSite() && to.name !== "warning") {
    // next({
    //   name: "warning",
    // });
  }
  if (from.query.ch && !to.query.ch) {
    next({
      path: to.path,
      query: Object.assign(to.query, { ch: from.query.ch }),
    });
  } else {
    next();
  }
});
router.afterEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    staticTotal();
  }
);
export default router;
