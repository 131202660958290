import request from "@/request";
import {
  Mobile,
  GraphicalCode,
  LoginResult,
  LoginServiceType,
  Account,
  ImageCodeType,
  AuthTokenType,
  GuidanceType,
  skipBootType,
  EmailGraphicalCode,
} from "@/types/login";
import { ResultCommon } from "@/types/common";
export const getAuthToken = (query: string): Promise<AuthTokenType> => {
  return request.post<AuthTokenType>({
    url: "/v-oauth-server/oauth/token?" + query,
  });
};
export const checkFrequentRequest = (data: Mobile): Promise<ImageCodeType> => {
  return request.post<ImageCodeType>({
    url: "/v-user/get/login/sms/verification/code",
    data,
  });
};
export const checkCaptchaCode = (
  data: GraphicalCode
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/check/login/sms/verification/code",
    data,
  });
};
export const getSendMobileCode = (
  data: GraphicalCode
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/login/sms/code",
    data,
  });
};

export const getSendEmailCode = (
  data: EmailGraphicalCode
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/login/email/code",
    data,
  });
};

export const getUserInfo = (): Promise<LoginResult> => {
  return request.post<LoginResult>({
    url: "/v-user/get/user",
  });
};

export const getFoundUserService = (): Promise<LoginServiceType> => {
  return request.post<LoginServiceType>({
    url: "/v-product/get/service/type",
    data: {
      appCode: "dec",
    },
  });
};

export const getAccoutCode = (data: Account): Promise<ImageCodeType> => {
  return request.post<ImageCodeType>({
    url: "/v-user/get/login/account/verification/code",
    data,
  });
};

export const getNoviceuidance = (
  data: GuidanceType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-message-center/reminds/select",
    data,
  });
};

export const runSkipBoot = (
  data: skipBootType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-message-center/reminds/handle",
    data,
  });
};
