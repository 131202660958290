import { createApp } from "vue";
import App from "@/layout/Index.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import i18n from "./lang/index";
import VueScrollTo from "vue-scrollto";
import elementZh from "element-plus/lib/locale/lang/zh-cn";
import elementEn from "element-plus/lib/locale/lang/en";
import px2rem from "@/utils/pxtorem";
import isMobile from "ismobilejs";
import LanguageSelect from "@/layout/header/LanguageSelect.vue";
import Logo from "@/components/Logo.vue";
import clipboard from "@/directive/clipboard";
import SecondPageHeader from "@/components/SecondPageHeader.vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { statisticalCookie, decodeAES } from "@/utils";
import { getCookie, removeCookie } from "@/utils/cookies";
import UAParser from "ua-parser-js";
const parser = new UAParser();
const upi = JSON.stringify(parser.getResult());
console.log(upi);
if (getCookie("uuid")) {
  decodeAES(getCookie("uuid"))
    .then((res) => {
      console.log("uuid解析成功");
    })
    .catch((e) => {
      console.log("uuid解析失败");
      removeCookie("uuid");
    });
}
statisticalCookie("upi", upi);
if (isMobile().any) {
  px2rem();
  statisticalCookie("platform", "h5");
} else {
  statisticalCookie("platform", "webApp");
}
// 加载全局样式
import "./style/index.scss";
const VueScrollToOptions = {
  offset: -80,
};

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(ElementPlus, {
    locale: store.state.appLanguage === "zh" ? elementZh : elementEn,
  })
  .use(VueScrollTo, VueScrollToOptions);
app.component("LanguageSelect", LanguageSelect);
app.component("Logo", Logo);
app.component("SecondPageHeader", SecondPageHeader);
app.directive("clipboard", clipboard);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
const init = async () => {
  await router.isReady();
  await app.mount("#app");
};
init();
