import request from "@/request";
import { DownloadDecHubResultType } from "@/types/common";
export const downloadDecHub = (): Promise<DownloadDecHubResultType> => {
  return request.post<DownloadDecHubResultType>({
    url: "/v-product/platform/upgrade",
    data: {
      appCode: "dec-hub",
    },
  });
};
