/**
 * v-clipboard 文字复制剪贴
 * author zhangxu
 * v-clipboard:copy="`要复制的文案`" v-clipboard:cut="`要剪贴的文案`"
 * v-clipboard:success="clipboardSuccess" // 成功回调
 * v-clipboard:error="clipboardError" // 失败回调
 */

import Clipboard from "clipboard";
import { Directive } from "vue";
const directive: Directive = {
  // 在绑定元素的 attribute 前
  // 或事件监听器应用前调用
  created(el, binding, vnode, prevVnode) {
    switch (binding.arg) {
      case "success":
        el._vClipBoard_success = binding.value;
        break;
      case "error":
        el._vClipBoard_error = binding.value;
        break;
      default:
        const clipboard = new Clipboard(el, {
          text: () => binding.value,
          action: () => (binding.arg === "cut" ? "cut" : "copy"),
        });
        clipboard.on("success", (e) => {
          const callback = el._vClipBoard_success;
          callback && callback(e);
        });
        clipboard.on("error", (e) => {
          const callback = el._vClipBoard_error;
          callback && callback(e);
        });
        el._vClipBoard = clipboard;
        break;
    }
    // 下面会介绍各个参数的细节
  },
  // 在绑定元素的父组件
  // 及他自己的所有子节点都更新后调用
  updated(el, binding, vnode, prevVnode) {
    switch (binding.arg) {
      case "success":
        el._vClipBoard_success = binding.value;
        break;
      case "error":
        el._vClipBoard_error = binding.value;
        break;
      default:
        el._vClipBoard.text = function () {
          return binding.value;
        };
        el._vClipBoard.action = () => (binding.arg === "cut" ? "cut" : "copy");
        break;
    }
  },
  // 绑定元素的父组件卸载后调用
  unmounted(el, binding, vnode, prevVnode) {
    if (!el._vClipboard) return;
    switch (binding.arg) {
      case "success":
        delete el._vClipBoard_success;
        break;
      case "error":
        delete el._vClipBoard_error;
        break;
      default:
        el._vClipBoard.destroy();
        delete el._vClipBoard;
        break;
    }
  },
};
export default directive;
