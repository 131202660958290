import { createI18n } from 'vue-i18n'
import zhCentent from './zh'
import enCentent from './en'
console.log(window.location.href)

const queryURLParams = (URL: string) => {
    let url = URL.split('?')[1]
    const urlSearchParams = new URLSearchParams(url)
    const params = Object.fromEntries(urlSearchParams.entries())
    return params
}
const lang = JSON.parse(localStorage.getItem('vuex') as string)?.appLanguage
const params = queryURLParams(window.location.href)
const i18n = createI18n({
    locale: params.lang || params.decLanguage || lang || 'zh', // set locale
    messages: {
        zh: zhCentent.lang,
        en: enCentent.lang
    }
})

export default i18n
